import { useFormik } from "formik";
import React, { useRef } from "react";
import { Dropdown, Form } from "react-bootstrap";
import PrimaryButton from "../../../../shared/ui/buttons/primary-button/PrimaryButton";
import SecondaryButton from "../../../../shared/ui/buttons/secondary-button/SecondaryButton";
import { FormGroupCustom } from "../../../../shared/ui/forms/FormGroupCustom";
import { InputCustom } from "../../../../shared/ui/forms/input/InputCustom";
import {findAction} from "../../../../utils/actionData";

export function DropRefuse({ ele, submitHandler }) {
	const refDrop = useRef(null);
	const formik = useFormik({
		initialValues: {
			notesCancellationRefused: "",
		},
		onSubmit: (values) => {
			submitHandler(values);
		},
	});
	const data = {
		name: "notesCancellationRefused",
		label: "Note",
		type: "input",
	};

	const changeValueHandler = (value) => {
		formik.setFieldValue(data.name, value);
	};
	const clickCancel = () => {
		formik.resetForm();
		refDrop.current.click();
	};
	return (
		<Dropdown
			ref={refDrop}
			className="mr-5"
			alignRight
			drop="down"
			onToggle={() => null}
			id="drop-actio">
			<Dropdown.Toggle className="btn-sm shared-button sh-primary">{findAction(ele.id).label}</Dropdown.Toggle>

			<Dropdown.Menu className="dropdown-menu m-0 mt-1 p-5 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
				<div className="">
					<div className="mb-3">
						<Form onSubmit={formik.handleSubmit} className="form-search">
							<FormGroupCustom label={"Note sul rifiuto"}>
								<InputCustom
									ele={data}
									val={formik.values.note}
									changeValue={changeValueHandler}
								/>
							</FormGroupCustom>
							<div className="d-flex justify-content-end">
								<SecondaryButton
									onClick={clickCancel}
									size="sm"
									className="mr-3">
									Annulla
								</SecondaryButton>
								<PrimaryButton type="submit" size="sm">
									Conferma
								</PrimaryButton>
							</div>
						</Form>
					</div>
				</div>
			</Dropdown.Menu>
		</Dropdown>
	);
}
