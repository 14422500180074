import React from "react";
import { Col, Row } from "react-bootstrap";
import {ProductCart} from "../../../cart/ProductCart";
import {TemplateSelectProduct} from "./templates/TemplateSelectProduct";


export function SelectProductForm({product, changeQuantityProduct, formik}) {
	return (
			<Row>
				{product && (
					<>
						<Col xs="12" className="mb-5">
							<ProductCart
								changeValue={() => {}}
								changeQuantity={(quantity) =>
									changeQuantityProduct && changeQuantityProduct(quantity)
								}
								product={product}
							/>
						</Col>
						<Col xs="12">
							<TemplateSelectProduct
								formik={formik}
								template={product.template}
							/>
						</Col>
					</>
				)}
			</Row>
	);
}
