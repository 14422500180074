import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormElements } from "../../../shared/ui/forms/FormElements";
import HeadingH4 from "../../../shared/ui/headings/headingH4/HeadingH4";
import Icon from "../../../shared/ui/icon/Icon";
import {
  formStructureEditPatientRegistryRow,
  formStructureEditPatientRegistryRowDomicile,
  formStructureEditPatientRegistryRowResidence,
} from "./patientRegistryData";
import PrimaryButton from "../../../shared/ui/buttons/primary-button/PrimaryButton";
import SecondaryButton from "../../../shared/ui/buttons/secondary-button/SecondaryButton";
import {
  createPatient,
  updatePatient,
} from "../../../../redux/order/orderCrud";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedCustomer } from "../../../../redux/customer/customerSlice";
import * as routeName from "../../../routes/routeNameAuthType";
import { GetPatientAction } from "../../../../redux/patient/patientActions";
import { useRoute } from "../../../utils/useRoute";
import { setErrorTypeAction } from "../../../../redux/errors/errorsActions";
import { validationsPatient } from "./data/validations";

const PatientRegistry = ({ patient = {} }) => {
  const customer = useSelector(selectSelectedCustomer);
  const dispatch = useDispatch();
  const { changeRoute } = useRoute();

  const formik = useFormik({
    initialValues: patient,
    validationSchema: validationsPatient,
    onSubmit: (values) => {
      if (patient?.id) {
        updatePatient(values)
          .then((res) => {
            const callback = () => changeRoute(routeName.selectedPatientPage);
            dispatch(GetPatientAction(res.id, callback));
          })
          .catch((err) => {
            const message = err?.response?.data?.Message;
            if (message === "ERR_CF_DUPLICATE") {
              dispatch(setErrorTypeAction("Codice Fiscale già registrato"));
            } else if (message === "ERR_CF_INCOHERENT") {
              dispatch(setErrorTypeAction("Codice Fiscale errato"));
            } else if (message === "ERR_EMAIL_DUPLICATE") {
              dispatch(setErrorTypeAction("L'email è già registrata"));
            } else if (message === "ERR_EMAIL_INCOHERENT") {
              dispatch(setErrorTypeAction("L'email non è corretta"));
            } else {
              dispatch(
                setErrorTypeAction("Errore nell'aggiornamento del paziente")
              );
            }
          });
      } else {
        createPatient(values, customer.id)
          .then((res) => {
            const callback = () => changeRoute(routeName.products);
            dispatch(GetPatientAction(res.id, callback));
          })
          .catch((err) => {
            const message = err?.response?.data?.Message;
            if (message === "ERR_CF_DUPLICATE") {
              dispatch(setErrorTypeAction("Codice Fiscale già registrato"));
            } else if (message === "ERR_CF_INCOHERENT") {
              dispatch(setErrorTypeAction("Codice Fiscale errato"));
            } else if (message === "ERR_EMAIL_DUPLICATE") {
              dispatch(setErrorTypeAction("L'email è già registrata"));
            } else if (message === "ERR_EMAIL_INCOHERENT") {
              dispatch(setErrorTypeAction("L'email non è corretta"));
            } else {
              dispatch(
                setErrorTypeAction("Errore nella creazione del paziente")
              );
            }
          });
      }
    },
  });

  return (
    <div>
      <div className="d-flex justify-content-between">
        <HeadingH4>Anagrafica paziente</HeadingH4>
        {/* <Icon icon="edit" /> */}
      </div>
      <Form onSubmit={formik.handleSubmit}>
        <FormElements
          structure={formStructureEditPatientRegistryRow}
          formik={formik}
        ></FormElements>
        <FormElements
          structure={formStructureEditPatientRegistryRowResidence}
          formik={formik}
        ></FormElements>
        <FormElements
          structure={formStructureEditPatientRegistryRowDomicile}
          formik={formik}
        ></FormElements>
        <Row className="justify-content-end">
          <Col xs="auto">
            <SecondaryButton
              onClick={() => changeRoute(routeName.selectedPatientPage)}
            >
              Annulla
            </SecondaryButton>
          </Col>
          <Col xs="auto">
            <PrimaryButton
              data-test={
                patient?.id ? "edit-patient-test" : "create-patient-test"
              }
            >
              {patient?.id ? "Modifica" : "Attiva nuovo trattamento"}
            </PrimaryButton>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PatientRegistry;
