import React, { useMemo } from "react";
import objectPath from "object-path";
// import SVG from "react-inlinesvg";
// import {OverlayTrigger, Tooltip} from "react-bootstrap";
// import {toAbsoluteUrl} from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
// import {SearchDropdown} from "../extras/dropdowns/search/SearchDropdown";
// import {UserNotificationsDropdown} from "../extras/dropdowns/UserNotificationsDropdown";
// import {QuickActionsDropdown} from "../extras/dropdowns/QuickActionsDropdown";
// import {MyCartDropdown} from "../extras/dropdowns/MyCartDropdown";
// import {LanguageSelectorDropdown} from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import { useSelector } from "react-redux";
import { selectIsRDO } from "../../../../app/modules/Auth/_redux/authRedux";

export function Topbar() {
	const uiService = useHtmlClassService();

	const layoutProps = useMemo(() => {
		return {
			// viewSearchDisplay: objectPath.get(uiService.config, "extras.search.display"),
			// viewNotificationsDisplay: objectPath.get(uiService.config, "extras.notifications.display"),
			// viewQuickActionsDisplay: objectPath.get(uiService.config, "extras.quick-actions.display"),
			// viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
			viewQuickPanelDisplay: objectPath.get(
				uiService.config,
				"extras.quick-panel.display"
			),
			// viewLanguagesDisplay: objectPath.get(uiService.config, "extras.languages.display"),
			viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
		};
	}, [uiService]);
	const { user } = useSelector((state) => state.auth);
	const isRdo = useSelector(selectIsRDO);

	return (
		<div className="topbar">
			{/* {layoutProps.viewSearchDisplay && (<SearchDropdown/>)}

            {layoutProps.viewNotificationsDisplay && (<UserNotificationsDropdown/>)}

            {layoutProps.viewQuickActionsDisplay && (<QuickActionsDropdown/>)}

            {layoutProps.viewCartDisplay && (<MyCartDropdown/>)} */}

			{layoutProps.viewQuickPanelDisplay &&
			/* <OverlayTrigger
					placement="bottom"
					overlay={<Tooltip id="quick-panel-tooltip">Organizzazioni</Tooltip>}>
					<div className="topbar-item">
						<div
							className="btn btn-icon btn-clean btn-lg mr-1"
							id="kt_quick_panel_toggle">
							<span className="svg-icon svg-icon-xl svg-icon-primary">
								aaa{" "}
							</span>
						</div>
					</div>
				</OverlayTrigger> */

			isRdo ? (
				<OverlayTrigger
					placement="bottom"
					overlay={
						<Tooltip id="quick-user-tooltip">Cambia Organizzazione</Tooltip>
					}>
					<div className="topbar-item">
						<div
							className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
							id="kt_quick_panel_toggle">
							<>
								<span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"></span>
								<span className="font-weight-bolder font-size-base d-none d-md-inline mr-3">
									{user.fullname}
								</span>
								{/* <span className="symbol symbol-35 symbol-light-success">                
                        <span className="symbol-label font-size-h5 font-weight-bold">{user.fullname[0]}</span>
                    </span> */}
							</>
						</div>
					</div>
				</OverlayTrigger>
			) : (
				<div className="topbar-item">
					<div
						className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
						style={{ cursor: "auto" }}>
						<>
							<span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"></span>
							<span className="font-weight-bolder font-size-base d-none d-md-inline mr-3">
								{user.fullname}
							</span>
						</>
					</div>
				</div>
			)}

			{/* {layoutProps.viewLanguagesDisplay && (<LanguageSelectorDropdown/>)} */}

			{layoutProps.viewUserDisplay && <QuickUserToggler />}
		</div>
	);
}
