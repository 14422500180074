import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectPatient } from "../../../redux/patient/patientSlice";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../_metronic/_partials/controls";
import * as routeName from "../../routes/routeNameAuthType";
import WizardOrder from "../../shared/app/wizard_order/WizardOrder";
import { nameSteps } from "../../shared/app/wizard_order/wizardOrderData";
import PatientDetail from "../../shared/design/patient/PatientDetail";
import PrimaryButton from "../../shared/ui/buttons/primary-button/PrimaryButton";
import { useRoute } from "../../utils/useRoute";
import {
	getOrder,
	getOrdersForPatient,
	searchOrders,
} from "../../../redux/order/orderCrud";
import ItemHistory from "../../shared/design/history/itemHistory/ItemHistory";
import { OrderDetailComponent } from "../order/component/summary/OrderDetailComponent";
import TitleCard from "../../shared/app/titleCard/TitleCard";
import IconBtn from "../../shared/ui/buttons/icon-button/IconButton";
import { pages, titlePrescriberApp } from "../../core/titleApp";
import { UseTitleApp } from "../../core/useTitleApp";
import { clearOrder } from "../../utils/ordersUtils";
import { setErrorTypeAction } from "../../../redux/errors/errorsActions";
import IsPermission from "../../shared/utils/IsPermission";
import {permissionsData} from "../../modules/Auth/_redux/permissionsData";

export function SelectPatientPage({ history }) {
	const dispatch = useDispatch();
	const patient = useSelector(selectPatient);
	const [listOrders, setListOrders] = useState([]);
	const { changeRoute } = useRoute();
	const setOrders = (orders, index = 0, newListOrders = []) => {
		if (index < orders.length) {
			getOrder(orders[index].codiceRDO)
				.then((res) => {
					setOrders(orders, index + 1, [...newListOrders, res]);
				})
				.catch((error) => {
					dispatch(setErrorTypeAction("Errore nella ricerca dell'ordine"));
				});
		} else {
			setListOrders(newListOrders.map((ele) => clearOrder(ele)));
		}
	};
	useEffect(() => {
		!patient && changeRoute(routeName.patientsPage);
		patient &&
			getOrdersForPatient(patient.id)
				.then((res) => {
					setListOrders(res.map((ele) => clearOrder(ele)));
				})
				.catch((error) => {
					dispatch(setErrorTypeAction("Errore nella ricerca degli ordini"));
				});
	}, []);
	const goProducts = () => {
		changeRoute(routeName.products);
	};

	const goEdit = () => {
		changeRoute(routeName.patientEditPage);
	};
	const { label } = UseTitleApp(pages.selectedPatient, false);
	return (
		<Container>
			<Row>
				<Col xs="12">
					<WizardOrder selectedStep={nameSteps.patient} />

					<Card>
						<CardHeader>
							<TitleCard title={label.title} subtitle={label.subtitle} />
						</CardHeader>

						<CardBody>
							<Row className="justify-content-end">
								<Col xs="auto">
									<IsPermission permissions={[permissionsData.canEdit]}>
										<IconBtn
											icon="edit"
											sizeBtn="16px"
											className="mb-2"
											onClick={goEdit}>
											Modifica anagrafica paziente
										</IconBtn>
									</IsPermission>
								</Col>
							</Row>
							<PatientDetail patient={patient} />

							<Row className="justify-content-end mt-3 mb-3">
								<Col xs="auto">
									<IsPermission permissions={[permissionsData.canEdit]}>
										<PrimaryButton onClick={goProducts}>
											Attiva nuovo trattamento
										</PrimaryButton>
									</IsPermission>
								</Col>
							</Row>
							{listOrders.map((ele) => (
								<OrderDetailComponent
									order={ele}
									showPatientInfo={false}
									orderDetail={ele.orderDetail}
								/>
							))}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}
