import React from "react";
import { Col } from "react-bootstrap";
import Caption from "../../../shared/ui/headings/caption/Caption";
import HeadingH3 from "../../../shared/ui/headings/headingH3/HeadingH3";
import { ProductCartRead } from "../../cart/ProductCartRead";

export default function ProductSummary({ ord }) {
	return (
		<div className="w-100">
			<Col xs="12" className="shared-product-card border-0 mb-0">
				<div className="info-product flex-row">
					<div className="head-info-product">
						<div className="title-product">
							<HeadingH3 className="primary">{ord?.title}</HeadingH3>
							<Caption className="mt-1">{ord?.description}</Caption>
							{/* {ord?.codeERP && <span className="mt-3">Cod.{ord?.codeERP}</span>} */}
						</div>
					</div>
					<div>
						<ProductCartRead product={ord} />
					</div>
				</div>
			</Col>
		</div>
	);
}
