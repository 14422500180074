import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../_metronic/_partials/controls";
import PatientRegistry from "../sample/anagraficaPaziente/PatientRegistry";
import { useSelector } from "react-redux";
import { selectPatient } from "../../../redux/patient/patientSlice";
import * as routeName from "../../routes/routeNameAuthType";
import { useRoute } from "../../utils/useRoute";
import TitleCard from "../../shared/app/titleCard/TitleCard";

export function PatientEditPage({ history }) {
	const patient = useSelector(selectPatient);
	const { changeRoute } = useRoute();
	useEffect(() => {
		!patient?.id && changeRoute(routeName.patientsPage);
	}, []);
	return (
		<Container>
			<Row>
				<Col xs="12">
					<Card>
						<CardHeader>
							<TitleCard
								title="Anagrafica paziente"
								subtitle="Modifica l'anagrafica del paziente"
							/>
						</CardHeader>
						<CardBody>
							<PatientRegistry patient={patient} />
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}
