import http from "../../app/utils/http";

export const CONTAINER_URL = "containers";


export const getContainers = (id) => http.get(`${CONTAINER_URL}?codiceCliente=${id}`);
export const containerMovement = (data) => http.post(`movement`, data);
export const containerCommunications = (data) => http.post(`communications`, data);
export const containerPharmacyCommunications = (customerId) => http.post(`communications/for-customer/${customerId}`);

