import React from "react";

export default function Badge({
  children,
  className = "",
  bgColor = "",
  color = "#fff",
}) {
  return (
    <span
      className={`badge-sh badge danger mt-1 ${className}`}
      style={{ backgroundColor: bgColor, color: color }}
    >
      {children}
    </span>
  );
}
