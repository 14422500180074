import React from "react";
import HeadingH4 from "../../../../shared/ui/headings/headingH4/HeadingH4";

export function TotalDelivery({ qta = 1, price, specifiedQuantity }) {
	const total = qta * price;
	return (
		<HeadingH4>
			{specifiedQuantity ? (
				<span>
					<span className="mr-3">TOT. Consegna</span>
					{qta > 1 ? `Q.tà ${qta} x € ${price.toFixed(2)}` : null}
					<span className="ml-5">€{total.toFixed(2)}</span>
				</span>
			) : (
				<span>
					<span className="mr-5">Consegna </span>
					<span className="">€{price?.toFixed(2)}</span>
				</span>
			)}
		</HeadingH4>
	);
}
