import React from "react";
const HeadingH3 = ({ children, className, ...rest }) => {
	return (
		<h3 className={`heading-3 ${className}`} {...rest}>
			{children}
		</h3>
	);
};

export default HeadingH3;
