import React, { useEffect, useState } from "react";
import Icon from "../../../ui/icon/Icon";
import { FormElements } from "../../../ui/forms/FormElements";
import { getStates } from "../../../../../redux/order/orderCrud";
import { typeData } from "./typeData";
import { states } from "../../../../utils/stateData";
import { getTodayDate, toEnDateString } from "../../../../utils/dateUtils";

const FiltersCol = ({ formik }) => {
  const searchAction = () => {};
  const todayDate = toEnDateString(getTodayDate());
  const formStructure = [
    {
      name: "searchAll",
      label: "Cerca",
      type: "input",
      col: { xs: 12 },
    },
    {
      name: "dateBy",
      label: "Dal",
      type: "input",
      dataElement: {
        type: "date",
        max: formik.values.dateTo ? formik.values.dateTo : todayDate,
      },
      col: { xs: 12 },
    },
    {
      name: "dateTo",
      label: "Al",
      type: "input",
      dataElement: {
        type: "date",
        min: toEnDateString(formik.values.dateBy),
        max: todayDate,
      },
      col: { xs: 12 },
    },
    // {
    // 	name: "order",
    // 	label: "Ordina per",
    // 	type: "input",
    // 	col: { xs: 12 },
    // },
    {
      type: "checkboxSingle",
      name: "excludeOtherChannels",
      label: "Canale",
      dataElement: {
        label: "Portale",
      },
      display: "flex-column",
      className: "mb-5",
      value: true,
      col: { xs: "12" },
    },
    {
      name: "state",
      label: "Stato",
      type: "checkbox",
      display: "flex-column",
      className: "mb-5",
      options: states.map((ele) => ({ value: ele.id, label: ele.label })),
      col: { xs: "12" },
    },
    {
      name: "type",
      label: "Tipologia",
      type: "checkbox",
      display: "flex-column",
      options: Object.keys(typeData).map((ele) => ({
        value: typeData[ele].name,
        label: typeData[ele].label,
      })),
      col: { xs: "12" },
    },
  ];

  return (
    <div>
      <FormElements structure={formStructure} formik={formik}></FormElements>
    </div>
  );
};

export default FiltersCol;
