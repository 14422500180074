import {
	faAddressCard,
	faBirthdayCake,
	faEnvelope,
	faFemale,
	faHome,
	faMale,
	faMobileAlt,
	faPhone,
	faUser,
	faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { dateformat_ggMMMyyyy } from "../../../utils/dateUtils";
import Caption from "../../ui/headings/caption/Caption";
import I from "../../ui/icon/I";

export default function PatientDetail({ patient }) {
	return (
		<div className="card-patient">
			<Row className="align-items-center">
				<Col>
					<div className="detail">
						<span>
							<I
								icon={
									patient?.gender === "Male"
										? faMale
										: patient?.gender === "Female"
										? faFemale
										: faUser
								}></I>
							{patient?.surname.toUpperCase()} {patient?.name}
						</span>
						{patient?.cf && (
							<span>
								<I icon={faAddressCard} size="1x"></I> {patient?.cf}
							</span>
						)}
						{patient?.birthDate && (
							<span>
								<I icon={faBirthdayCake} size="1x"></I>
								{patient?.birthDate
									? dateformat_ggMMMyyyy(patient.birthDate)
									: ""}
							</span>
						)}
						{patient?.email && (
							<span>
								<I icon={faEnvelope} size="1x"></I>
								{patient?.email}
							</span>
						)}
						{patient?.phone && (
							<span>
								<I icon={faPhone} size="1x"></I>
								{patient?.phone}
							</span>
						)}
						{patient?.mobile && (
							<span>
								<I icon={faMobileAlt} size="1x"></I>
								{patient?.mobile}
							</span>
						)}
					</div>
				</Col>
			</Row>
			<Row className="align-items-center mt-3">
				<Col>
					<Row>
						{patient?.residence && (
							<Col xs="12" md="auto">
								<>
									<div className="residence">
										<I icon={faHome}></I>

										<span>Residente in</span>
										<span>
											{patient?.residence.street
												? patient?.residence.street
												: "-"}{" "}
											{patient?.residence.number
												? `, ${patient?.residence.number}`
												: null}
										</span>
										<span>{patient?.residence.city}</span>
										<span>{patient?.residence.province}</span>
										<span>{patient?.residence.cap}</span>
										<span>
											{patient?.residence.internal
												? `Int. ${patient?.residence.internal}`
												: null}
										</span>
									</div>
								</>
							</Col>
						)}
						{patient?.domicile && (
							<Col xs="12" md="auto">
								<>
									<div className="domicile">
										<I icon={faMapMarkerAlt}></I>
										<span>Domiciliato in</span>
										<span>
											{patient?.domicile.street
												? patient?.domicile.street
												: "-"}{" "}
											{patient?.domicile.number
												? `, ${patient?.domicile.number}`
												: null}
										</span>
										<span>{patient?.domicile.city}</span>
										<span>{patient?.domicile.province}</span>
										<span>{patient?.domicile.cap}</span>
										<span>
											{patient?.domicile.internal
												? `Int. ${patient?.domicile.internal}`
												: null}
										</span>
									</div>
								</>
							</Col>
						)}
					</Row>
				</Col>
			</Row>
		</div>
	);
}
