import React from "react";
import Button from "../button/Button";

const SecondaryButton = ({
  children,
  className,
  variant = "secondary",
  sizeBtn,
  type = "button",
  ...rest
}) => {
  return (
    <Button
      variant={variant}
      type={type}
      sizeBtn={sizeBtn}
      className={`sh-secondary ${className}`}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default SecondaryButton;
