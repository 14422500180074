/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { selectCustomerType } from "../../../../../app/modules/Auth/_redux/authRedux";
import routes from "../../../../../app/routes/auth";
import * as routeName from "../../../../../app/routes/routeNameAuthType";
import ItemMenu from "../../../../../app/shared/ui/menu/itemMenu/ItemMenu";
import { useRoute } from "../../../../../app/utils/useRoute";
import { selectCartLength } from "../../../../../redux/cart/cartSlice";
import { checkIsActive } from "../../../../_helpers";
import { usePermissions } from "../../../../../app/shared/utils/usePemissions";
import { resetErrors } from "../../../../../redux/errors/errorsSlice";
export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url);
  };
  const { issetPermission } = usePermissions();
  const cartLength = useSelector(selectCartLength);
  const customerType = useSelector(selectCustomerType);

  const listRoutes = (routes.list?.filter((ele) => ele.navTitle) || [])
    .filter(
      (r) =>
        !r.roles ||
        (r.roles.includes(customerType) &&
          (r.permissions && r.permissions.length
            ? issetPermission(r.permissions)
            : true))
    )
    .map((r) => (r.name === routeName.cart ? { ...r, notify: cartLength } : r));
  const { changeRoute } = useRoute();

  const clickItemMenuHandler = (route) => {
    dispatch(resetErrors());
    changeRoute(route);
  };

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {listRoutes.map((ele) => (
          <ItemMenu
            testName={ele.name}
            key={ele.path}
            icon={ele.icon}
            label={ele.navTitle.label}
            isActive={!!getMenuItemActive(ele.path)}
            notify={ele.notify}
            onClick={(event) => {
              clickItemMenuHandler(ele.name);
            }}
          />
          // <li
          //     key={ele.path}
          //     className={`menu-item menu-item-rel ${getMenuItemActive(ele.path)}`}
          //     aria-haspopup="true">
          //     <a className="menu-link"
          //        onClick={(event) => {
          //            event.preventDefault();
          //            changeRoute(ele.name)
          //        }} href={ele.path}>
          //         <span className="menu-text">{ele.navTitle.label}</span>
          //     </a>
          // </li>
        ))}
      </ul>
    </div>
  );
}
