import React from "react";
import LabelValue from "../../../../../../shared/ui/labelValue/LabelValue";
import {
	templateFormVentilotherapyOtherInfo,
	templateFormVentilotherapyPrescription,
} from "../templateForm";
import { Col, Row } from "react-bootstrap";
import Caption from "../../../../../../shared/ui/headings/caption/Caption";

export function TemplateVentilotherapyRead({ data }) {
	console.log(data);
	return (
		<Row>
			<Col xs="12">
				<Row>
					{templateFormVentilotherapyPrescription.map((field, i) => (
						<Col
							key={i}
							xs={field.col.xs}
							sm={field.col.sm}
							md={field.col.md}
							lg={field.col.lg}
							className={`${field?.className || ""} mb-3`}>
							<small className="mr-2">{field.label}</small>
							<br/>
							<span>{(data && data[field.name]) || "-"}</span>
						</Col>
					))}
				</Row>
			</Col>
			<Col xs="12">
				<Row>
					{templateFormVentilotherapyOtherInfo.map((field, i) => (
						<Col
							key={i}
							xs={field.col.xs}
							sm={field.col.sm}
							md={field.col.md}
							lg={field.col.lg}
							className="mb-3">
							<small className="mr-2">{field.label}</small>
							<br/>
							<span>{(data && data[field.name]) || "-"}</span>
						</Col>
					))}
				</Row>
			</Col>
		</Row>
	);
}
