import {createSelector, createSlice} from "@reduxjs/toolkit";

const initialCartState = {
  cart: [],
};


export const cartSlice = createSlice({
  name: "cart",
  initialState: initialCartState,
  reducers: {
    // listProducts
    setCart: (state, action) => {
      state.cart = action.payload;
    },
    addProductToCart: (state, action) => {
      state.cart = [...state.cart, action.payload];
    },
    editProductToCart: (state, action) => {
      state.cart = state.cart.map(ele => ele.id === action.payload.id ? action.payload : ele);
    },
    deleteProductToCart: (state, action) => {
      state.cart = state.cart.filter(el => el.id !== action.payload);
    }
  }
});

export const {setCart, addProductToCart, editProductToCart, deleteProductToCart} = cartSlice.actions;

export const selectCart = (state) => state.cart.cart;
export const selectCartLength = createSelector(selectCart, (cart) => cart.length);

