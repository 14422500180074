export const pathologiesData = [
    {
        value: 'BPCO',
        label: 'BPCO'
    },
    {
        value: 'polmonari',
        label: 'Malattie polmonari'
    },
    {
        value: 'Cifoscoliosi',
        label: 'Cifoscoliosi'
    },
    {
        value: 'Neoplasia',
        label: 'Neoplasia'
    },
    {
        value: 'trapianto',
        label: 'In attesa trapianto (cuore/polmoni)'
    },
    {
        value: 'fibrosi',
        label: 'Fibrosi Cistica'
    },
    {
        value: 'neuromuscolare',
        label: 'Malattia neuromuscolare'
    },
    {
        value: 'ipertensione',
        label: 'Ipertensione Polmonare'
    },
    {
        value: 'OSAS',
        label: 'OSAS'
    }
]
