import React from "react";
import { Modal } from "react-bootstrap";
import PrimaryButton from "../buttons/primary-button/PrimaryButton";
import SecondaryButton from "../buttons/secondary-button/SecondaryButton";
import Icon from "../icon/Icon";

const ModalMsg = ({
	className,
	showModal = false,
	text,
	btn,
	modalStyle,
	templateHeader,
	templateBody,
	templateFooter,
	clickAction,
	closeAction,
	closeButton = false,
	size,
	...rest
}) => {
	const handleClick = (action) => {
		clickAction && clickAction(action);
	};

	const mStyle = {
		backgroundColor: modalStyle?.style.bgColor || "",
		color: modalStyle?.style.color || "",
	};

	return (
		<>
			<Modal
				className={`shared-modal ${className}`}
				show={showModal}
				onHide={closeAction}
				backdrop="static"
				keyboard={false}
				size={size}>
				<Modal.Header closeButton={closeButton} style={mStyle}>
					<div className="d-flex align-items-center">
						{modalStyle?.icon && (
							<Icon
								icon={modalStyle?.icon.ico}
								color={modalStyle?.icon.color}
								size="x26"
								pointer={false}></Icon>
						)}
						<Modal.Title>
							{templateHeader ? templateHeader : text?.title}
						</Modal.Title>
					</div>
				</Modal.Header>
				<Modal.Body style={mStyle}>
					{templateBody ? templateBody : text?.body}
				</Modal.Body>
				<Modal.Footer style={mStyle}>
					{templateFooter ? (
						templateFooter
					) : (
						<div className="d-flex justify-content-end">
							{btn?.map((ele, index) => {
								const type =
									!ele.type || ele.type === "" ? "primary" : ele.type;
								return type === "primary" ? (
									<div key={index} className="mx-2">
										<PrimaryButton
											onClick={() => handleClick(ele.action)}
											{...ele.dataType}>
											{ele.label}
										</PrimaryButton>
									</div>
								) : (
									<div key={index} className="mx-2">
										<SecondaryButton
											onClick={() => handleClick(ele.action)}
											{...ele.dataType}>
											{ele.label}
										</SecondaryButton>
									</div>
								);
							})}
						</div>
					)}
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default ModalMsg;
