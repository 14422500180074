import React from "react";
import { Col, Row } from "react-bootstrap";
import HeadingH4 from "../../../shared/ui/headings/headingH4/HeadingH4";
import I from "../../../shared/ui/icon/I";
import { dateformat_ggMMMyyyy } from "../../../utils/dateUtils";
import { faAddressBook } from "@fortawesome/free-solid-svg-icons";
import Caption from "../../../shared/ui/headings/caption/Caption";

export function DetailOrder({ orderDetail }) {
	return (
		<>
			<HeadingH4 className="gray">Dettaglio richiesta</HeadingH4>
			<div className="d-flex align-items-center">
				<div>
					<span className="mr-3 ml-3 p-3">
						<I icon={faAddressBook} color="#686C82" size="2x" />
					</span>
				</div>
				<div className="">
					<Row className="mt-3 mb-3">
						<Col xs="auto">
							<Caption className="mr-2">Richiedente</Caption>
							<span>
								{orderDetail?.applicant?.surname.toUpperCase()}{" "}
								{orderDetail?.applicant?.name}
							</span>
						</Col>
						<Col xs="auto">
							<Caption className="mr-2">Richiesta del</Caption>
							<span>
								{dateformat_ggMMMyyyy(orderDetail?.applicant?.data || "-")}
							</span>
						</Col>
					</Row>
					<Row className="mb-3">
						<Col xs="auto">
							<Caption className="mr-2">Prescrittore</Caption>
							<span>
								{orderDetail?.prescriber?.surname?.toUpperCase()}{" "}
								{orderDetail?.prescriber?.name}
							</span>
						</Col>
						<Col xs="auto">
							<Caption className="mr-2">Codice</Caption>
							<span>{orderDetail?.prescriber?.code}</span>
						</Col>
						<Col xs="auto">
							<Caption className="mr-2">Prescrizione</Caption>
							<span>{orderDetail?.prescription?.code}</span>
						</Col>
						<Col xs="auto">
							<Caption className="mr-2">Prescrizione del</Caption>
							<span>
								{dateformat_ggMMMyyyy(orderDetail?.prescription?.date)}
							</span>
						</Col>
					</Row>
					<Row className="mb-3">
						<Col xs="auto">
							<Caption className="mr-2">Diagnosi</Caption>
							<span>{orderDetail?.diagnosis}</span>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
}
