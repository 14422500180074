export const clearOrder = (order) => {
    return {
        ...order,
        rdoId: order.codiceRDO,
        orderDetail: order.dettaglioOrdine,
        orderRequest: {
            templateRDO: order.codiceTemplateRDO,
        },
    }
}
