export const states = [
	{
		id: 1,
		labelAction:
			"RdO in attesa di approvazione",
		code: "Aperta.InApprovazione",
		label: "In approvazione",
		labelButton: "Non lavorata",
		tagBgColor: "#24AA02cc",
	},
	{
		id: 2,
		labelAction: "RdO non approvata",
		code: "Chiusa.Cancellata.NonApprovata",
		label: "Non approvata",
		labelButton: "Rifiuta",
		tagBgColor: "#E20000cc",
	},
	{
		id: 3,
		labelAction: "RdO inviata",
		code: "Aperta.Inviata",
		label: "Inviata",
		labelButton: "Invia",
		tagBgColor: "#E28800cc",
	},
	{
		id: 4,
		labelAction:
			"RdO in lavorazione",
		code: "Aperta.In lavorazione",
		label: "In lavorazione",
		labelButton: "Approva",
		tagBgColor: "#e28800cc",
	},
	{
		id: 5,
		labelAction: "Evasa",
		code: "Chiusa.Conclusa",
		label: "Evasa",
		labelButton: "Evadi",
		tagBgColor: "#24ac02cc",
	},
	{
		id: 6,
		labelAction: "RdO cancellata su iniziativa del Back Office",
		code: "Chiusa.Cancellata.BO",
		label: "Annullata BO",
		labelButton: "Cancella",
		tagBgColor: "#E20000cc",
	},
	{
		id: 7,
		labelAction:
			"RdO cancellata su richiesta del Cliente",
		code: "Chiusa.Cancellata.Cliente",
		label: "Annullata Cliente",
		labelButton: "Cancella",
		tagBgColor: "#E20000cc",
	},
	{
		id: 8,
		labelAction: "Vi è una richiesta di cancellazione in corso",
		code: "Aperta.RichiestaCancellazione",
		label: "Richiesta cancellazione",
		labelButton: "Cancella",
		tagBgColor: "#7A1717cc",
	},
	{
		id: 9,
		labelAction: "Programmata",
		code: "Aperta.Programmata",
		label: "Programmata",
		labelButton: "Programmata",
		tagBgColor: "#7A1717cc",
	},
	{
		id: 10,
		labelAction: "Evasa Parzialmente",
		code: "Aperta.EvasioneParziale",
		label: "Evasa Parzialmente",
		labelButton: "Evasa Parzialmente",
		tagBgColor: "#7A1717cc",
	},
	{
		id: 11,
		labelAction: "Evasa Parzialmente",
		code: "Chiusa.ConclusaParziale",
		label: "Evasa Parzialmente",
		labelButton: "Evasa Parzialmente",
		tagBgColor: "#7A1717cc",
	},
];

export const findStates = (id: number | string) => {
	return states.find((state) => state.id === +id);
};
