import {createSelector, createSlice} from "@reduxjs/toolkit";
import {customerTypeName} from "./customerData";

const initialCustomerState = {
  customers: [],
  selectedCustomer: undefined
};


export const customerSlice = createSlice({
  name: "customer",
  initialState: initialCustomerState,
  reducers: {
    // listProducts
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },
    setSelectedCustomer: (state, action) => {
      state.selectedCustomer = action.payload;
    },
    resetCustomers: (state) => {
      state.customers = []
    },
    resetSelectedCustomer: (state, action) => {
      state.selectedCustomer = undefined;
    }
  }
});

export const {setCustomers, setSelectedCustomer, resetCustomers, resetSelectedCustomer} = customerSlice.actions;

export const selectCustomers = (state) => state.customer.customers;
export const selectSelectedCustomer = (state) => state.customer.selectedCustomer;
export const selectIsPharmacy = createSelector(selectSelectedCustomer, (customer) => customerTypeName.pharmacy === customer.type)


