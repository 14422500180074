import { createSelector } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import { customerType } from "../../../../redux/customer/customerData";
import { selectSelectedCustomer } from "../../../../redux/customer/customerSlice";
import { permissionsData } from "./permissionsData";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
};
const trasformScopeToArray = (scopes) =>
  typeof scopes === "string" ? [scopes] : scopes;
const calcScope = (scopes = []) => {
  const scopesClear = trasformScopeToArray(scopes);
  return scopesClear
    .map((ele) => {
      const arr = ele.split(":");
      return arr[arr.length - 1];
    })
    .reduce((acc, ele) => [...acc, ...(acc.includes(ele) ? [] : [ele])], []);
};

export const reducer = persistReducer(
  { storage, key: "auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { data } = action.payload;
        let user = {
          username: data.entities.profile.preferred_username,
          email: data.entities.profile.preferred_username,
          available_app: data.entities.profile.available_app,
          associatedCustomersId:
            data.entities.profile?.associatedCustomersId?.split(",") || [],
          resource_scope: calcScope(data.entities.profile?.resource_scope),
          fullname: data.entities.profile.name,
          firstname: data.entities.profile.given_name,
          lastname: data.entities.profile.family_name,
          role: data.entities.profile.role,
        };
        localStorage.setItem("customerId", user.associatedCustomersId[0]);

        return { authToken: data.entities.access_token, user };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        localStorage.removeItem("customerId");
        return initialAuthState;
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (data) => ({
    type: actionTypes.Login,
    payload: { data },
  }),
  logout: () => ({ type: actionTypes.Logout }),
};

export const selectUser = (state) => state.auth.user;
export const selectCustomerType = createSelector(
  selectUser,
  selectSelectedCustomer,
  (user, customer) => {
    return user?.role === customerType.bo ? customerType.bo : customer?.type;
  }
);
export const selectIsRDO = createSelector(
  selectUser,
  (user) => customerType.rdomanager === user.role
);
export const selectPermissions = createSelector(selectUser, (user) => [
  ...(user?.resource_scope?.length === 1 && user.resource_scope[0] === "canview"
    ? []
    : [permissionsData.canEdit]),
]);
