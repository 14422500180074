import { createSlice } from "@reduxjs/toolkit";


const initialSpinnerState = {
    count: 0,
    show: false,
    size: "lg",
}

export const spinnerSlice = createSlice({
    name: "spinner",
    initialState: initialSpinnerState,
    reducers: {
        spinnerCreate: (state, action) => {
            if (action.payload.show) {
                state.count++;
                state.show = action.payload.show;
                state.size = action.payload.size ? action.payload.size : state.size;
            } else {
                state.count--;
                if (state.count === 0) {
                    state.show = action.payload.show;
                }
            }
        },
    },
});