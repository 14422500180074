export const pages = {
	selectedProduct: "selectedProduct",
	selectedPatient: "selectedPatient",
	products: "products",
	summary: "summary",
	summaryFinal: "summaryFinal",
};

export const titlePharmacyApp = {
	[pages.selectedProduct]: {
		title: "Dettaglio prodotto",
		subtitle: "Inserisci i dettagli del prodotto",
	},
	[pages.products]: {
		title: "Prodotti",
		subtitle: "Seleziona il prodotto.",
	},
	[pages.selectedProduct]: {
		title: "Dettagli Prodotti",
		subtitle: "Inserire la quantità ed eventuali note.",
    },
    [pages.summary]: {
		title: "Finalizza ordine",
		subtitle: "Compila i campi e finalizza ordine",
	},
    [pages.summaryFinal]: {
		title: "Riepilogo ordine",
		subtitle: "Verifica e invia ordine",
	},
};

export const titlePrescriberApp = {
	[pages.selectedProduct]: {
		title: "Dettagli Prescrizione",
		subtitle:
			"Inserimento i dettagli della prescrizione e finalizzazione della richiesta.",
	},
	[pages.selectedPatient]: {
		title: "Paziente",
		subtitle:
			"Visualizzazione dettagli paziente e/o attivazione nuovo trattamento.",
	},
	[pages.products]: {
		title: "Lista Trattamenti",
		subtitle: "Selezionare il trattamento per il paziente",
	},
	[pages.summary]: {
		title: "Finalizza la richiesta",
		subtitle: "Compilare i campi per finalizzare la richiesta.",
	},
	[pages.summaryFinal]: {
		title: "Riepilogo Prescrizione",
		subtitle: "Verifica e invia prescrizione",
	},
};
