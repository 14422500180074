/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import CallbackPage from "./pages/callback/CallbackPage";
import SignoutCallbackPage from "./pages/callback/SignoutCallbackPage";
import SsoPage from "./modules/Auth/pages/SsoPage";
import { GetCustomerAction } from "../redux/customer/customerActions";
import { selectCustomers } from "../redux/customer/customerSlice";
import { SilentRenewComponent } from "./pages/silent_renew/SilentRenewComponent";

export default function Routes(props) {
  const { isLogged, isAuthorizedApp } = useSelector(
    ({ auth }) => ({
      isLogged: auth.user != null,
      isAuthorizedApp:
        auth.user &&
        auth.user.available_app &&
        ((typeof auth.user.available_app === "string" &&
          auth.user.available_app === process.env.REACT_APP_NAME) ||
          (typeof auth.user.available_app !== "string" &&
            auth.user.available_app.includes(process.env.REACT_APP_NAME))),
    }),
    shallowEqual
  );
  const isAuthorized = isLogged && isAuthorizedApp;

  return (
    <Switch>
      <Route
        exact={true}
        path={"/silent_renew"}
        component={SilentRenewComponent}
      />
      <Route path="/callback" component={CallbackPage} />
      <Route path="/logout" component={SignoutCallbackPage} />
      <Route path="/sso" component={SsoPage} />
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage isLogged={isLogged} />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />

      {/* <Route path="/logout" component={Logout} /> */}

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <>
          {
            <Layout>
              <BasePage />
            </Layout>
          }
        </>
      )}
    </Switch>
  );
}
