/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import Icon from "../../../../shared/ui/icon/Icon";
import iconAssociate from "./../../../../assets/img/icon/associate.svg";
import iconDisassociate from "./../../../../assets/img/icon/disassociate.svg";

export function ActionsColumnFormatter(
	cellContent,
	row,
	rowIndex,
	{ associate, setCollect }
) {
	return (
		<div className="d-flex justify-content-end align-items-center">
			{row.deliveryPlace ? (
				<img
					src={iconDisassociate}
					title="Rientro"
					alt="Rientro"
					className="mr-3 pointer"
					onClick={(event) => {
						event.stopPropagation();
						associate(row);
					}}
				/>
			) : (
				<img
					src={iconAssociate}
					title="Consegna"
					alt="Consegna"
					className="mr-3 pointer"
					onClick={(event) => {
						event.stopPropagation();
						associate(row);
					}}
				/>
			)}
			<Icon
				className="justify-content-end"
				icon="truck"
				disabled={!row.deliveryPlace}
				clickAction={(event) => {
					event.stopPropagation();
					setCollect(row);
				}}></Icon>
		</div>
	);
}
