import { totalmem } from "os";
import React from "react";
import HeadingH4 from "../../../../shared/ui/headings/headingH4/HeadingH4";

export function TotalProduct({ total }) {
	return (
		<HeadingH4>
			<span className="mr-5">TOT. Prodotti</span>
			<span className="mr-l">€{total.toFixed(2)}</span>
		</HeadingH4>
	);
}
