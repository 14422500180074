import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormElements } from "../../../shared/ui/forms/FormElements";
import HeadingH4 from "../../../shared/ui/headings/headingH4/HeadingH4";
import Icon from "../../../shared/ui/icon/Icon";
import {
  formStructureConsegnaRow,
  formStructureContattiRow,
  formStructureIndirizzoConsegnaRow,
  formStructureNoteRow,
  formStructureNominativeRow,
  optionDelivery,
} from "./templateGenFarData";
import {
  createPatient,
  getDeliveries,
  getDeliveriesPatient,
  updatePatient,
} from "../../../../redux/order/orderCrud";
import BoxSearchPatients from "./component/BoxSearchPatients";
import { getPatient, getPatients } from "../../../../redux/patient/patientCrud";
import PrimaryButton from "../../../shared/ui/buttons/primary-button/PrimaryButton";
import { setStopLoader } from "../../../shared/ui/loader/redux/loadSlice";
import { useDispatch, useSelector } from "react-redux";
import { UseTemplateGenFar } from "./UseTemplateGenFar";
import SecondaryButton from "../../../shared/ui/buttons/secondary-button/SecondaryButton";
import { useFormik } from "formik";
import {
  validationsTemplateGenFar,
  validationsTemplateGenFarWithIdPatient,
  validationsTemplateGenFarWithPatient,
} from "../templateGenPub/templateGenPubData";
import { setErrorTypeAction } from "../../../../redux/errors/errorsActions";
import {
  selectIsPharmacy,
  selectSelectedCustomer,
} from "../../../../redux/customer/customerSlice";
import axios from "axios";
let stopAction = false;
let timeoutAction;
let cancelToken;
const TemplateGenFar = ({ customer, template, createOrder, goBack, order }) => {
  const [validations, setValidations] = useState(validationsTemplateGenFar);
  const [selectPatient, setSelectedPatient] = useState();
  const isPharmacy = useSelector(selectIsPharmacy);
  const selectedCustomer = useSelector(selectSelectedCustomer);
  const formik = useFormik({
    initialValues: {},
    validationSchema: validations,
    onSubmit: (values) => {
      submitForm();
    },
  });
  const dispatch = useDispatch();
  const [patients, setPatients] = useState([]);
  const [deliveries, setDeliveries] = useState([]);
  const [pharmacyDeliveries, setPharmacyDeliveries] = useState([]);
  useEffect(() => {
    order &&
      setTimeout(() => {
        formik.setValues(order);
      }, 100);
  }, [order]);
  const setPatient = (pat) => {
    stopAction = true;

    getPatient(pat.id).then((res) => {
      setPatients([]);
      setSelectedPatient({ ...res });
      formik.setFieldValue("patient", { ...res });
      setTimeout(() => {
        stopAction = false;
      }, 1000);
    });
  };
  useEffect(() => {
    if (
      (formik.values.patient?.name ||
        formik.values.patient?.surname ||
        formik.values.patient?.cf) &&
      !stopAction &&
      !formik.values.patient?.id
    ) {
      dispatch(setStopLoader(true));
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      cancelToken = axios.CancelToken.source();
      getPatients(
        {
          name: formik.values.patient?.name,
          surname: formik.values.patient?.surname,
          cf: formik.values.patient?.cf,
          customerCode: selectedCustomer.id,
        },
        cancelToken
      ).then((res) => {
        timeoutAction && clearTimeout(timeoutAction);
        timeoutAction = setTimeout(() => {
          dispatch(setStopLoader(false));
        }, 500);
        setPatients(res);
      });
    } else {
      dispatch(setStopLoader(false));
      setPatients([]);
    }
  }, [
    formik.values.patient?.name,
    formik.values.patient?.surname,
    formik.values.patient?.cf,
  ]);

  useEffect(() => {
    if (!!formik.values.patient?.id) {
      setValidations(validationsTemplateGenFarWithIdPatient);
      getDeliveriesPatient(formik.values.patient?.id, template)
        .then((res) => setDeliveries(res))
        .catch((error) => {
          dispatch(
            setErrorTypeAction(
              "Errore nel caricamento delle opzioni di consegna"
            )
          );
        });
    } else {
      setValidationPatientOrPharmacy();
    }
  }, [formik.values.patient?.id]);
  useEffect(() => {
    stopAction = false;
    getDeliveries(selectedCustomer.id).then((list) => {
      setPharmacyDeliveries(list);
    });
    return () => {
      dispatch(setStopLoader(false));
    };
  }, []);
  const { formStructureDeliveryOptionsRow } = UseTemplateGenFar(
    formik.values?.delivery === optionDelivery.pharmacy.value
      ? pharmacyDeliveries
      : deliveries
  );
  const showFinalize =
    formik.values.patient?.id ||
    formik.values?.delivery === optionDelivery.pharmacy.value;
  const submitForm = () => {
    if (showFinalize) {
      if (formik.values?.delivery === optionDelivery.pharmacy.value) {
        createOrder({
          ...formik.values,
          deliveryData: pharmacyDeliveries.find(
            (ele) => ele.id.toString() === formik.values.deliveryType.toString()
          ),
        });
      } else {
        updatePatient(formik.values.patient).then(
          (res) => {
            createOrder({
              ...formik.values,
              deliveryData: deliveries.find(
                (ele) =>
                  ele.id.toString() === formik.values.deliveryType.toString()
              ),
            });
          },
          (err) => {
            const message = err?.response?.data?.Message;
            if (message === "ERR_CF_DUPLICATE") {
              dispatch(setErrorTypeAction("Codice Fiscale già registrato"));
            } else if (message === "ERR_CF_INCOHERENT") {
              dispatch(setErrorTypeAction("Codice Fiscale errato"));
            } else if (message === "ERR_EMAIL_DUPLICATE") {
              dispatch(setErrorTypeAction("L'email è già registrata"));
            } else if (message === "ERR_EMAIL_INCOHERENT") {
              dispatch(setErrorTypeAction("L'email non è corretta"));
            } else {
              dispatch(
                setErrorTypeAction("Errore nell'aggiornamento del paziente")
              );
            }
          }
        );
      }
    } else {
      createPatient({ ...formik.values.patient }, customer.id).then(
        (res) => {
          formik.setFieldValue("patient", { ...res });
        },
        (err) => {
          const message = err?.response?.data?.Message;
          if (message === "ERR_CF_DUPLICATE") {
            dispatch(setErrorTypeAction("Codice Fiscale già registrato"));
          } else if (message === "ERR_CF_INCOHERENT") {
            dispatch(setErrorTypeAction("Codice Fiscale errato"));
          } else if (message === "ERR_EMAIL_DUPLICATE") {
            dispatch(setErrorTypeAction("L'email è già registrata"));
          } else if (message === "ERR_EMAIL_INCOHERENT") {
            dispatch(setErrorTypeAction("L'email non è corretta"));
          } else {
            dispatch(setErrorTypeAction("Errore nella creazione del paziente"));
          }
        }
      );
    }
  };

  const resetPatient = () => {
    resetDataChangeDelivery(formik.values.delivery);
  };
  const resetDataChangeDelivery = (delivery) => {
    formik.setValues({ delivery });
    setDeliveries([]);
    setSelectedPatient(undefined);
  };
  useEffect(() => {
    // formik.values.delivery === "pharmacy" && resetPatient();
    setValidationPatientOrPharmacy();
  }, [formik.values.delivery]);

  const setValidationPatientOrPharmacy = () => {
    formik.values.delivery === "patient"
      ? setValidations(validationsTemplateGenFarWithPatient)
      : setValidations(validationsTemplateGenFar);
  };

  return (
    <div>
      <Form autocomplete="off" onSubmit={formik.handleSubmit}>
        <HeadingH4>Consegna</HeadingH4>
        <FormElements
          style={{ maxHeight: "42px" }}
          changeValue={(name, val) => {
            resetDataChangeDelivery(val);
            formik.setFieldValue(name, val);
          }}
          structure={formStructureConsegnaRow}
          formik={formik}
        ></FormElements>
        {formik.values.delivery === "patient" && (
          <>
            <HeadingH4>Nominativo</HeadingH4>
            <FormElements
              structure={
                formik.values?.patient?.id
                  ? formStructureNominativeRow.map((ele) => ({
                      ...ele,
                      disabled:
                        selectPatient &&
                        !selectPatient.cf &&
                        ele.name === "patient.cf"
                          ? false
                          : true,
                    }))
                  : formStructureNominativeRow
              }
              formik={formik}
              rightTemplate={
                formik.values?.patient?.id && (
                  <>
                    <Col xs="auto">
                      <Icon
                        icon="close"
                        size="26px"
                        clickAction={resetPatient}
                      />
                    </Col>
                  </>
                )
              }
            ></FormElements>
            {/* {formik.values?.patient?.id && (
								<Icon icon="close" clickAction={resetPatient} />
							)} */}

            {patients && !!patients.length && (
              <BoxSearchPatients patients={patients} setPatient={setPatient} />
            )}
            <HeadingH4>Indirizzo consegna</HeadingH4>
            <FormElements
              structure={formStructureIndirizzoConsegnaRow}
              formik={formik}
            ></FormElements>
            <HeadingH4>Contatti</HeadingH4>
            <FormElements
              structure={formStructureContattiRow}
              formik={formik}
            ></FormElements>
          </>
        )}
        {((pharmacyDeliveries &&
          pharmacyDeliveries.length &&
          formik.values?.delivery === optionDelivery.pharmacy.value) ||
          (deliveries && !!deliveries.length)) && (
          <>
            <HeadingH4>Opzioni Consegna</HeadingH4>
            <FormElements
              structure={formStructureDeliveryOptionsRow}
              formik={formik}
            ></FormElements>
          </>
        )}
        <HeadingH4>Note</HeadingH4>
        <FormElements
          structure={formStructureNoteRow}
          formik={formik}
        ></FormElements>
        <Row className="justify-content-end mt-3">
          <Col xs="auto">
            <SecondaryButton onClick={goBack}>
              {isPharmacy ? "Annulla" : "Indietro"}
            </SecondaryButton>
          </Col>
          <Col xs="auto">
            <PrimaryButton data-test="order-finalize">
              {showFinalize ? "Finalizza" : "Procedi"}
            </PrimaryButton>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default TemplateGenFar;
