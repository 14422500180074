import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  setErrorTypeAction,
  setSuccessErrorAction,
} from "../../../../../redux/errors/errorsActions";
import { updateOrders } from "../../../../../redux/order/orderCrud";
import IconBtn from "../../../../shared/ui/buttons/icon-button/IconButton";
import Error from "../../../../shared/ui/forms/error/Error";
import { InputCustom } from "../../../../shared/ui/forms/input/InputCustom";
import Icon from "../../../../shared/ui/icon/Icon";

export default function RowTag({
  isOnlyRead,
  listNR,
  paramValidation,
  idOrder,
  updateOrderHandler,
  setOrderInEdit,
}) {
  const [tag, setTag] = useState(undefined);
  const [error, setError] = useState(undefined);
  useEffect(() => {
    setOrderInEdit && setOrderInEdit(!!tag);
  }, [tag]);
  const dispatch = useDispatch();
  const changeValueInputTag = (value) => {
    setTag(value);
  };

  const updateCall = (ordersCodes) => {
    updateOrders(idOrder, { ordersCodes }).then(
      (res) => {
        dispatch(setSuccessErrorAction("Codice ordine aggiunto con successo"));
        setTag("");
        console.log(res);
        updateOrderHandler && updateOrderHandler(res);
      },
      (err) => {
        if (
          err?.response?.data?.Message ===
          "OrderCodeAlreadyAssociatedWithDifferentRdo"
        ) {
          dispatch(
            setErrorTypeAction(
              "Il numero d'ordine inserito è già associato ad un'altra RDO"
            )
          );
        } else {
          dispatch(
            setErrorTypeAction("Errore nell'aggiunta del codice ordine")
          );
        }
      }
    );
  };
  const addTagInList = () => {
    if (
      tag &&
      paramValidation &&
      paramValidation !== "NO_ORD" &&
      paramValidation !== "undefined" && //TODO
      !tag.startsWith(paramValidation)
    ) {
      setError({ errorType: paramValidation });
      return;
    }
    tag && updateCall([...listNR, tag]);
    setError(undefined);
  };
  const deleteItemTag = (item) => {
    updateCall(listNR.filter((ele) => ele !== item));
  };

  return (
    <Row className="align-items-center">
      <Col xs="4">
        {!isOnlyRead ? (
          <>
            <label className="form-label">Aggiungi Numeri d'ordine</label>
            <InputCustom
              ele={{
                name: "tag",
                type: "input",
              }}
              val={tag}
              changeValue={changeValueInputTag}
              rightTemplate={
                <IconBtn
                  className={!tag ? "disabled" : ""}
                  icon="add"
                  sizeBtn="20px"
                  onClick={addTagInList}
                ></IconBtn>
              }
            />
            {error && tag && (
              <Error>Tipo "{error.errorType}" non presente</Error>
            )}
          </>
        ) : (
          listNR &&
          listNR.length > 0 && (
            <label className="form-label">Numeri d'ordine</label>
          )
        )}
      </Col>
      <Col xs="auto"></Col>
      <Col xs="12">
        <div className={`item-row ${isOnlyRead ? "read" : "mt-4"}`}>
          {listNR.map((ele, i) => (
            <span className={`tag-item `} key={i}>
              {ele}
              {!isOnlyRead && (
                <Icon
                  icon="close"
                  size="10px"
                  clickAction={() => deleteItemTag(ele)}
                />
              )}
            </span>
          ))}
        </div>
      </Col>
    </Row>
  );
}
