import * as Yup from "yup";
import { cfRegex } from "../../../utils/cfRegex";
import { getTodayDate, toEnDateString } from "../../../utils/dateUtils";

const todayDate = toEnDateString(getTodayDate());

export const formStructureRichiedenteRow = [
  {
    name: "applicant.name",
    label: "Nome",
    type: "input",
  },
  {
    name: "applicant.surname",
    label: "Cognome",
    type: "input",
  },
  {
    name: "applicant.data",
    label: "Data richiesta",
    type: "input",
    dataElement: {
      type: "date",
      max: todayDate,
    },
  },
].map((ele) => ({ ...ele, col: { xs: "12", sm: "6", md: "4", lg: "3" } }));

export const formStructureMedicoRow = [
  {
    name: "prescriber.code",
    label: "Codice medico",
    type: "input",
  },
  {
    name: "prescriber.name",
    label: "Nome",
    type: "input",
  },
  {
    name: "prescriber.surname",
    label: "Cognome",
    type: "input",
  },
].map((ele) => ({ ...ele, col: { xs: "12", sm: "6", md: "4", lg: "3" } }));

export const formStructurePrescrizioneRow = [
  {
    name: "prescription.code",
    label: "Codice prescrizione",
    type: "input",
  },
  {
    name: "prescription.date",
    label: "Data prescrizione",
    type: "input",
    dataElement: {
      type: "date",
      max: todayDate,
    },
  },
].map((ele) => ({ ...ele, col: { xs: "12", sm: "6", md: "4", lg: "3" } }));

export const formStructureDiagnosiRow = [
  {
    name: "diagnosis",
    label: "Diagnosi",
    type: "textarea",
    col: { xs: "12" },
  },
];

export const validationsTemplateGenPub = Yup.object().shape({
  // applicant: Yup.object().shape({
  // 	name: Yup.string().required("Obbligatorio"),
  // 	surname: Yup.string().required("Obbligatorio"),
  // 	data: Yup.date()
  // 		.max(todayDate, `La data non può essere maggiore di oggi`)
  // 		.required("Obbligatorio"),
  // }),
  prescriber: Yup.object().shape({
    //code: Yup.string().required("Obbligatorio"),
    name: Yup.string().required("Obbligatorio").nullable(),
    surname: Yup.string().required("Obbligatorio").nullable(),
  }),
  prescription: Yup.object().shape({
    code: Yup.string().required("Obbligatorio").nullable(),
    date: Yup.date()
      .max(todayDate, `La data non può essere maggiore di oggi`)
      .required("Obbligatorio")
      .nullable(),
  }),
  diagnosis: Yup.string().required("Obbligatorio").nullable(),
});

export const validationPatient = Yup.object().shape({
  name: Yup.mixed().required("Obbligatorio").nullable(),
  surname: Yup.mixed().required("Obbligatorio").nullable(),
  cf: Yup.string().matches(cfRegex, "Inserire CF valido").nullable(),
  phone: Yup.number()
    .typeError("Inserire un numero valido")
    .required("Obbligatorio")
    .nullable(),
  mobile: Yup.number()
    .typeError("Inserire un numero valido")
    .required("Obbligatorio")
    .nullable(),
  //email: Yup.string().email("Inserire mail valida").required("Obbligatorio").nullable(),
  domicile: Yup.object().shape({
    street: Yup.mixed().required("Obbligatorio").nullable(),
    number: Yup.number()
      .typeError("Inserire un numero valido")
      .required("Obbligatorio")
      .nullable(),
    internal: Yup.number()
      .nullable()
      .typeError("Inserire un numero valido")
      .nullable(),
    cap: Yup.number()
      .typeError("Inserire un numero valido")
      .required("Obbligatorio")
      .nullable(),
    city: Yup.mixed().required("Obbligatorio").nullable(),
    province: Yup.mixed().required("Obbligatorio").nullable(),
  }),
});
export const validationsTemplateGenFar = Yup.object().shape({
  delivery: Yup.string().required("Obbligatorio").nullable(),
  deliveryType: Yup.mixed().required("Obbligatorio").nullable(),
});
export const validationsTemplateGenFarWithPatient = Yup.object().shape({
  delivery: Yup.string().required("Obbligatorio").nullable(),
  patient: validationPatient,
});
export const validationsTemplateGenFarWithIdPatient = Yup.object().shape({
  delivery: Yup.string().required("Obbligatorio").nullable(),
  patient: validationPatient,
  deliveryType: Yup.mixed().required("Obbligatorio").nullable(),
});
