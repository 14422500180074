import React from "react";
const Caption = ({ children, className, size, ...rest }) => {
	return (
		<div
			className={`caption ${className}`}
			style={{ fontSize: size, minHeight: "18px" }}
			{...rest}>
			{children}
		</div>
	);
};

export default Caption;
