import { Routes } from "./models/routes";
import * as routeName from "./routeNameAuthType";
import { ProductsPage } from "../pages/products/ProductsPage";
import { Sample } from "../pages/sample/Sample";
import { CartPage } from "../pages/cart/CartPage";
import { HistoricalPage } from "../pages/historical/HistoricalPage";
import { SelectProductPage } from "../pages/products/selectProduct/SelectProductPage";
import { OrderPage } from "../pages/order/OrderPage";
import { PatientPage } from "../pages/patients/PatientPage";
import { PatientsPage } from "../pages/patients/PatientsPage";
import { customerTypeName } from "../../redux/customer/customerData";
import { Containers } from "../pages/containers/Containers";
import { SelectPatientPage } from "../pages/patients/SelectPatientPage";
import { PatientEditPage } from "../pages/patients/PatientEditPage";
import { EditProductPage } from "../pages/products/selectProduct/EditProductPage";

const routes: Routes = {
	list: [
		{
			path: "/storico",
			component: HistoricalPage,
			name: routeName.historical,
			icon: "list",
			navTitle: { label: "Storico" },
			roles:  [customerTypeName.pharmacy]
		},
		{
			path: "/richieste",
			component: HistoricalPage,
			name: routeName.historical,
			icon: "list",
			navTitle: { label: "Visualizza Richieste" },
			roles: [customerTypeName.prescriber, customerTypeName.bo]
		},
		{
			path: "/prodotti",
			component: ProductsPage,
			name: routeName.products,
			icon: "store",
			navTitle: { label: "Prodotti" },
			roles: [customerTypeName.pharmacy],
			permissions: ['canEdit']
		},
		{
			path: "/prodotti",
			component: ProductsPage,
			name: routeName.products,
			roles: [customerTypeName.prescriber]
		},
		{
			path: "/acquisto",
			component: SelectProductPage,
			name: routeName.selectProduct,
			permissions: ['canEdit']
		},
		{
			path: "/modifica-ordine",
			component: EditProductPage,
			name: routeName.selectEditProduct,
			permissions: ['canEdit']
		},
		{
			path: "/ordine",
			component: OrderPage,
			name: routeName.orderPage,
			permissions: ['canEdit']
		},
		{
			path: "/paziente",
			component: PatientPage,
			name: routeName.patientPage,
		},
		{
			path: "/paziente/edit",
			component: PatientEditPage,
			name: routeName.patientEditPage,
			permissions: ['canEdit']
		},
		{
			path: "/paziente-sel",
			component: SelectPatientPage,
			name: routeName.selectedPatientPage,
			roles: [customerTypeName.prescriber],
		},
		{
			path: "/pazienti",
			component: PatientsPage,
			name: routeName.patientsPage,
			icon: "user-circle",
			navTitle: { label: "Pazienti" },
			roles: [customerTypeName.prescriber]
		},

		{
			path: "/carrello",
			component: CartPage,
			name: routeName.cart,
			icon: "cart",
			navTitle: { label: "Carrello" },
			notify: "3",
			roles: [customerTypeName.pharmacy],
			permissions: ['canEdit']
		},
		{
			path: "/magazzino",
			component: Containers,
			name: routeName.containersPage,
			icon: "user-circle",
			navTitle: { label: "Tracking" },
			roles: [customerTypeName.pharmacy],
			permissions: ['canEdit']
		}
	],
	redirect: routeName.redirectPath,
	firstPage: routeName.firstPage,
};
export default routes;
