export const isFull = (obj: any = {}): boolean =>
  obj && isObject(obj) && !!Object.keys(obj).length;

export const isEmpty = (obj: any = {}): boolean => isFull(obj);

export const isObject = (obj: any): boolean =>
  typeof obj === "object" && obj !== null && obj.constructor === Object;

export const copyObject = (obj: any): any => JSON.parse(JSON.stringify(obj));

export const objDeleteProperties = (obj: any = {}, properties: any[] = []) => {
    const dataObj: any = copyObject(obj);
    properties.forEach(ele => {
        eval('delete dataObj.'+ele)
    })
    return dataObj
}
