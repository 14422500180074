import React, { useEffect, useState } from "react";
import IconBtn from "../../../../shared/ui/buttons/icon-button/IconButton";
import { InputCustom } from "../../../../shared/ui/forms/input/InputCustom";
import Caption from "../../../../shared/ui/headings/caption/Caption";
import { updateOrders } from "../../../../../redux/order/orderCrud";
import {
  setErrorTypeAction,
  setSuccessErrorAction,
} from "../../../../../redux/errors/errorsActions";
import { useDispatch } from "react-redux";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { useEffectOnlyUpdate } from "../../../../shared/utils/use_custom/useEffectCustom";

export default function RowDelivery({
  orderDetail,
  order,
  updateDateHandler,
  setOrderInEdit,
}) {
  const [deliveryDateNotes, setDeliveryDateNotes] = useState(
    order?.dettaglioOrdine?.deliveryNotes || ""
  );
  const [showEdit, setShowEdit] = useState(false);
  useEffect(() => {
    setOrderInEdit && setOrderInEdit(showEdit);
  }, [showEdit]);

  useEffectOnlyUpdate(() => {
    setDeliveryDateNotes(order?.dettaglioOrdine?.deliveryNotes || "");
  }, [order?.dettaglioOrdine?.deliveryNotes]);

  const dispatch = useDispatch();
  const changeValueInputDeliveryDate = (value) => {
    setDeliveryDateNotes(value);
  };

  const addDeliveryDate = () => {
    updateOrders(order.codiceRDO, {
      dettaglioOrdine: {
        ...order.dettaglioOrdine,
        deliveryNotes: deliveryDateNotes,
      },
    }).then(
      (res) => {
        dispatch(
          setSuccessErrorAction("Data consegna aggiornata con successo")
        );
        //setDeliveryDateNotes("");
        setShowEdit(false);
        updateDateHandler && updateDateHandler(res);
      },
      (err) => {
        dispatch(setErrorTypeAction("Errore nella modifica data consegna"));
        setShowEdit(false);
      }
    );
  };
  const cancelHandler = () => {
    setDeliveryDateNotes(order?.dettaglioOrdine?.deliveryNotes || "");
    setShowEdit(false);
  };

  return (
    <>
      {showEdit ? (
        <>
          <Caption className="mr-2">Consegna prevista</Caption>
          <InputCustom
            maxLength={"50"}
            ele={{
              name: "deliveryNotes",
              type: "input",
            }}
            val={deliveryDateNotes}
            changeValue={changeValueInputDeliveryDate}
            rightTemplate={
              <>
                <IconBtn
                  icon="close"
                  sizeBtn="20px"
                  onClick={cancelHandler}
                ></IconBtn>
                <IconBtn
                  className={!deliveryDateNotes ? "disabled" : ""}
                  icon="save_black"
                  sizeBtn="20px"
                  onClick={addDeliveryDate}
                ></IconBtn>
              </>
            }
          />
        </>
      ) : (
        <div>
          <Caption className="mr-2">Consegna prevista</Caption>
          <span className="mr-3 d-flex">
            {orderDetail?.deliveryNotes || "-"}
            {order.deliveryNotesEditable && (
              <IconBtn
                className={"ml-3"}
                isFontAwesome={true}
                icon={faPencilAlt}
                sizeBtn="20px"
                onClick={() => setShowEdit(true)}
              ></IconBtn>
            )}
          </span>
        </div>
      )}
    </>
  );
}
