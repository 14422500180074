import React, { lazy } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { Routes } from "./models/routes";
import { shallowEqual, useSelector } from "react-redux";
import LoaderFullScreenApp from "../component/load/LoaderFullScreenApp";

interface DynamicRouteProps {
	routes: Routes;
}
const DynamicRoute = ({ routes }: DynamicRouteProps) => {
	const { spinnerProperties } = useSelector(
		(state: any) => ({
			spinnerProperties: state.spinner,
		}),
		shallowEqual
	);
	const firstPage = routes.list.find(ele => ele.path === routes.firstPage)? routes.firstPage : (routes.list[0]?.path || routes.redirect);
	return (
		<React.Suspense fallback={<LoaderFullScreenApp />}>
			<LoaderFullScreenApp />
			<Switch>
				{firstPage && <Redirect exact from="/" to={firstPage} />}
				{routes.list.map((ele) => {
					return (
						<Route
							key={ele.path}
							exact
							path={ele.path}
							component={ele.component}></Route>
					);
				})}
				{routes.redirect ? <Redirect from="*" to={routes.redirect} /> : null}
			</Switch>
		</React.Suspense>
	);
};

export default DynamicRoute;
