import React from "react";
import {templateNameId, templateNameType} from "../../../../cart/templateData";
import {TemplateNote} from "./TemplateNote";
import {TemplateVentilotherapy} from "./TemplateVentilotherapy";
import {TemplateOxigen} from "./TemplateOxigen";

export function TemplateSelectProduct({formik, template}) {
	const templateList = {
		[templateNameId[templateNameType.note]]: <TemplateNote formik={formik} />,
		[templateNameId[templateNameType.ossigeno]]: <TemplateOxigen formik={formik} />,
		[templateNameId[templateNameType.ventiloterapia]]: <TemplateVentilotherapy formik={formik} />,
	}
	return (
		<>
			{templateList[template]}
		</>
	);
}
