import {
  faAddressCard,
  faFemale,
  faMale,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { templateRdoType } from "../../../../../redux/order/orderData";
import Badge from "../../../../shared/ui/badge/Badge";
import PrimaryButton from "../../../../shared/ui/buttons/primary-button/PrimaryButton";
import I from "../../../../shared/ui/icon/I";
import Hr from "../../../../shared/ui/lineBrake/Hr";
import { findAction } from "../../../../utils/actionData";
import { dateformat_ggMMMyyyy } from "../../../../utils/dateUtils";
import { findStates } from "../../../../utils/stateData";
import { DetailDelivery } from "../DetailDelivery";
import { DetailOrder } from "../DetailOrder";
import { DropDelete } from "./DropDelete";
import { DropRefuse } from "./DropRefuse";
import RowTag from "./RowTag";
import {
  resetModal,
  setModal,
} from "../../../../shared/redux/modal/modalSlice";

export function HeaderDetailOrder({
  orderDetail,
  showPatientInfo = true,
  order,
  changeStateOrder,
  updateOrderHandler,
  setOrderInEdit,
}) {
  const dispatch = useDispatch();
  const buttonList = [
    {
      label: `Annulla`,
      action: "cancelActionModal",
      type: "secondary",
      dataType: {},
    },
    {
      label: `Conferma`,
      action: "confirmActionModal",
      type: "primary",
      dataType: {},
    },
  ];
  const cancelActionModal = () => {
    dispatch(resetModal());
  };
  const confirmActionModal = (ele, data) => {
    dispatch(resetModal());
    changeStateOrder && changeStateOrder(order.codiceRDO, ele.id, data);
  };

  const changeState = (ele, data) => {
    if (ele.msg) {
      dispatch(
        setModal(
          buttonList,
          {
            title: ele.msg.title,
            body: ele.msg.body,
          },
          {
            cancelActionModal,
            confirmActionModal: () => confirmActionModal(ele, data),
          }
        )
      );
    } else {
      changeStateOrder && changeStateOrder(order.codiceRDO, ele.id, data);
    }
  };

  const patient = orderDetail?.patient;
  const state = findStates(order.state);

  const lastModified = order?.cronologyState
    ? order?.cronologyState[order?.cronologyState?.length - 1]
    : undefined;

  return (
    <Row className="headerDetailOrder">
      <Col xs="12">
        <Row>
          <Col xs="12">
            <Row>
              {order.rdOLabel && (
                <>
                  <Col>
                    <div className="time-row">
                      <span>
                        N.RDO:
                        <br />
                        SG{order.rdOLabel}
                      </span>
                      <span>
                        Canale:
                        <br />
                        {order?.channel === "sg" ? (
                          <Badge
                            bgColor="#DEE67F"
                            color="#616800"
                            className={"sm"}
                          >
                            PORTALE
                          </Badge>
                        ) : (
                          <Badge
                            bgColor="#e6e6e6"
                            color="#035792"
                            className={"sm"}
                          >
                            Altro
                          </Badge>
                        )}
                      </span>
                      <span>
                        <span className={"mr-1"}>
                          N. Ordine:
                          <br />
                          {order.orderNumber ? order.orderNumber : "-"}
                        </span>
                      </span>

                      <span>
                        del: <br />
                        {dateformat_ggMMMyyyy(order.dataCreazioneOrdine)}
                      </span>
                    </div>
                    <div className="time-row">
                      <small>
                        {order.clientCodeERP && (
                          <span>Codice cliente: {order.clientCodeERP}</span>
                        )}
                        {order.patientCode && (
                          <span>ID Paziente: {order.patientCode}</span>
                        )}
                      </small>
                    </div>
                  </Col>
                  <Col xs="auto">
                    Ultima Modifica:{" "}
                    {lastModified ? (
                      <>
                        {lastModified?.surname?.toUpperCase()}{" "}
                        {lastModified?.name}
                        <small>
                          {" "}
                          | {dateformat_ggMMMyyyy(lastModified?.date)}
                        </small>
                      </>
                    ) : (
                      "-"
                    )}
                  </Col>
                </>
              )}
            </Row>
          </Col>
          <Col xs="12" md="6">
            {patient && showPatientInfo && (
              <div className="detail mb-2">
                <span>
                  <I
                    icon={
                      patient?.gender === "Male"
                        ? faMale
                        : patient?.gender === "Female"
                        ? faFemale
                        : faUser
                    }
                  ></I>
                  {patient?.surname?.toUpperCase()} {patient?.name}
                </span>
                {patient?.cf && (
                  <span>
                    <I icon={faAddressCard} size="1x"></I> {patient?.cf}
                  </span>
                )}
              </div>
            )}
            <Badge bgColor={state?.tagBgColor}>{state?.labelAction}</Badge>
            {/* passare color type come class es. primary / error / success */}
          </Col>
          <Col xs="12" md="6">
            <Row className="justify-content-end">
              {order.actions?.map((ele, key) => (
                <Col xs="auto" key={key}>
                  {ele.id === 5 ? (
                    <DropDelete
                      ele={ele}
                      clickClient={() =>
                        changeState(findAction(ele.id) || ele, { proxState: 7 })
                      }
                      clickBO={() =>
                        changeState(findAction(ele.id) || ele, { proxState: 6 })
                      }
                    />
                  ) : ele.id === 6 ? (
                    <DropRefuse
                      ele={ele}
                      submitHandler={(data) =>
                        changeState(findAction(ele.id) || ele, data)
                      }
                    />
                  ) : (
                    <PrimaryButton
                      onClick={() => changeState(findAction(ele.id) || ele)}
                      size="sm"
                    >
                      {findAction(ele.id).label}
                    </PrimaryButton>
                  )}
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Hr />
        <Row>
          <Col xs="12">
            {order.orderRequest.templateRDO === templateRdoType.GENFAR ? (
              <DetailDelivery
                setOrderInEdit={setOrderInEdit}
                orderDetail={orderDetail}
                order={order}
                updateDateHandler={updateOrderHandler}
              />
            ) : (
              <DetailOrder orderDetail={orderDetail} />
            )}
          </Col>
          <Col xs="12">
            <RowTag
              setOrderInEdit={setOrderInEdit}
              paramValidation={order?.orderCodeType?.type}
              isOnlyRead={!order.orderCodeType}
              listNR={order?.ordersCodes || []}
              idOrder={order.codiceRDO}
              updateOrderHandler={updateOrderHandler}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
