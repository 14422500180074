import * as requestFromServer from "./patientCrud";

import {
	setErrorTypeAction,
	setSuccessErrorAction,
} from "../errors/errorsActions";
import {getPatient} from "./patientCrud";
import {setPatient} from "./patientSlice";



export const GetPatientAction = (id, callback) => (dispatch) => {
	return getPatient(id)
		.then((response) => {
			dispatch(setPatient(response));
			callback && callback()
		})
		.catch((error) => {
			dispatch(setErrorTypeAction("Errore nel caricamento dei dati del paziente"));
		});
};

