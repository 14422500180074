import React, { useEffect, useState } from "react";
import {
	changeOrderAction,
	changeOrderState,
	getOrder,
} from "../../../../../redux/order/orderCrud";
import { OrderDetailComponent } from "./OrderDetailComponent";
import { clearOrder } from "../../../../utils/ordersUtils";
import { useDispatch } from "react-redux";
import {
	setErrorTypeAction,
	setSuccessErrorAction,
} from "../../../../../redux/errors/errorsActions";

export function OrderDetailLoad({ idOrder, reloadOrder, setOrderInEdit }) {
	const [order, setOrder] = useState();
	const dispatch = useDispatch();
	const loadOrderCall = () => {
		getOrder(idOrder)
			.then((res) => {
				setOrderData(res);
				reloadOrder && reloadOrder(res);
			})
			.catch((error) => {
				dispatch(setErrorTypeAction("Errore nella ricerca dell'ordine"));
			});
	};
	const setOrderData = (res) => {
		setOrder(clearOrder(res));
	};
	useEffect(() => {
		loadOrderCall();
	}, [idOrder]);
	const changeStateOrder = (cod, id, data) => {
		changeOrderAction(cod, id, data)
			.then((res) => {
				setOrderData(res);
				reloadOrder && reloadOrder(res);
				dispatch(setSuccessErrorAction("Stato aggiornato con successo"));
			})
			.catch((error) => {
				dispatch(setErrorTypeAction("Errore nella caricamento"));
			});
	};
	return (
		<>
			{order && (
				<>
					<OrderDetailComponent
						setOrderInEdit={setOrderInEdit}
						order={order}
						orderDetail={order.orderDetail}
						changeStateOrder={changeStateOrder}
						updateOrderHandler={(order) => setOrderData(order)}
					/>
				</>
			)}
		</>
	);
}
