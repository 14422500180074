import { getTodayDate, toEnDateString } from "../../../utils/dateUtils";

const todayDate = toEnDateString(getTodayDate());
export const formStructureEditPatientRegistryRow = [
	{
		name: "surname",
		label: "Cognome",
		type: "input",
	},
	{
		name: "name",
		label: "Nome",
		type: "input",
	},
	{
		name: "cf",
		label: "C.F.",
		type: "input",
	},
	{
		name: "birthdate",
		label: "Data di nascita",
		type: "input",
		dataElement: {
			type: "date",
			max: todayDate,
		},
	},
	{
		name: "phone",
		label: "Telefono",
		type: "input",
	},
	{
		name: "mobile",
		label: "Cellulare",
		type: "input",
	},
	{
		name: "email",
		label: "Email",
		type: "input",
	},
].map((ele) => ({ ...ele, col: { xs: "12", sm: "6", lg: "3" } }));

export const formStructureEditPatientRegistryRowResidence = [
	{
		name: "residence.street",
		label: "Indirizzo di residenza",
		type: "input",
		col: { xs: "12", sm: "6", lg: "3" },
	},
	{
		name: "residence.number",
		label: "Numero",
		type: "input",
		col: { xs: "4", sm: "3", lg: "1" },
	},
	{
		name: "residence.internal",
		label: "Interno",
		type: "input",
		col: { xs: "4", sm: "3", lg: "1" },
	},
	{
		name: "residence.cap",
		label: "CAP",
		type: "input",
		col: { xs: "4", sm: "3", lg: "1" },
	},
	{
		name: "residence.city",
		label: "Città",
		type: "input",
		col: { xs: "12", sm: "6", lg: "3" },
	},
	{
		name: "residence.province",
		label: "Provincia",
		type: "input",
		col: { xs: "12", sm: "3", lg: "1" },
	},
];
export const formStructureEditPatientRegistryRowDomicile = [
	{
		name: "domicile.street",
		label: "Indirizzo di domicilio",
		type: "input",
		col: { xs: "12", sm: "6", lg: "3" },
	},
	{
		name: "domicile.number",
		label: "Numero",
		type: "input",
		col: { xs: "4", sm: "3", lg: "1" },
	},
	{
		name: "domicile.internal",
		label: "Interno",
		type: "input",
		col: { xs: "4", sm: "3", lg: "1" },
	},
	{
		name: "domicile.cap",
		label: "CAP",
		type: "input",
		col: { xs: "4", sm: "3", lg: "1" },
	},
	{
		name: "domicile.city",
		label: "Città",
		type: "input",
		col: { xs: "12", sm: "6", lg: "3" },
	},
	{
		name: "domicile.province",
		label: "Provincia",
		type: "input",
		col: { xs: "12", sm: "3", lg: "1" },
	},
];
