import * as Yup from "yup";
import {templateNameId, templateNameType} from "../../../../cart/templateData";

export const FormVentilotherapyPrescriptionValidations = Yup.object().shape({
    // ipap: Yup.string().required("Obbligatorio"),
    // epap: Yup.string().required("Obbligatorio"),
    // freq: Yup.string().required("Obbligatorio"),
    // tins: Yup.string().required("Obbligatorio")
})
export const FormOxygenValidations = Yup.object().shape({
	/* pathologies: Yup.string().required("Obbligatorio"), */
	flowRest: Yup.string().required("Obbligatorio").nullable(),
	underStressFlow: Yup.string().required("Obbligatorio").nullable(),
	nightFlow: Yup.string().required("Obbligatorio").nullable(),
});
export const FormNoteValidations = Yup.object().shape({});
export const validationTemplatesForm = {
    [templateNameId[templateNameType.note]]: FormNoteValidations,
    [templateNameId[templateNameType.ossigeno]]: FormOxygenValidations,
    [templateNameId[templateNameType.ventiloterapia]]: FormVentilotherapyPrescriptionValidations,
}
