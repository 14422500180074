import React from "react";
import { Col, Row } from "react-bootstrap";
import { dateformat_ggMMMyyyy } from "../../../../utils/dateUtils";

const ItemDropdown = ({
	user,
	clickHandler,
}: {
	user: any;
	clickHandler: (user: any) => void;
}) => {
	return (
		<div className="item" onClick={() => clickHandler(user)}>
			<Row className="w-100">
				<Col xs="4">
					<span>{`${user.surname?.toUpperCase()} ${user.name}`}</span>
				</Col>

				<Col xs="3" className="text-right">
					<span>
						{user.birthDate ? dateformat_ggMMMyyyy(user.birthDate) : ""}
					</span>
				</Col>
				<Col xs="1" className="text-right">
					<span>{user.gender?.slice(0, 1)}</span>
				</Col>
				<Col xs="4" className="text-left">
					<span>{user.cf}</span>
				</Col>
			</Row>
		</div>
	);
};

export default ItemDropdown;
