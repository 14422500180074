import {
	faAddressCard,
	faBirthdayCake,
	faFemale,
	faMale,
	faMobileAlt,
	faPhone,
	faUser,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { dateformat_ggMMMyyyy } from "../../../utils/dateUtils";
import Caption from "../headings/caption/Caption";
import I from "../icon/I";
const UserDetail = ({ customer = {} }) => {
	const dataCustomer = [
		{
			label: "C.F.",
			value: customer.cf || "-",
			icon: faAddressCard,
		},
		{
			label: "NATO IL",
			value: dateformat_ggMMMyyyy(customer.birthDate) || "-",
			icon: faBirthdayCake,
		},
		{
			label: "TEL",
			value: customer.phone || "-",
			icon: faPhone,
		},
		{
			label: "CELL",
			value: customer.mobile || "-",
			icon: faMobileAlt,
		},
	];

	return (
		<div className="shared-customer-detail">
			<span className="mr-5">
				<Caption className="primary">
					<I
						className="mr-3"
						icon={
							customer?.gender === "Male"
								? faMale
								: customer?.gender === "Female"
								? faFemale
								: faUser
						}></I>
					{customer.surname?.toUpperCase()} {customer.name}
				</Caption>
			</span>
			{dataCustomer.map((ele) => (
				<span className="mx-5" key={ele.label}>
					<Caption className="primary">
						<I className="mr-3" icon={ele.icon} title={ele.label}></I>
						{ele.value}
					</Caption>
				</span>
			))}
		</div>
	);
};

export default UserDetail;
