import React from "react";
import Icon from "../../icon/Icon";

const ItemMenu = ({ testName, icon, label, isActive, notify, ...rest }) => {
  return (
    <div
      data-test={testName ? `voice_menu_${testName}` : "voice_menu"}
      className={`shared-itemMenu ${isActive ? "active" : ""}`}
      {...rest}
    >
      <div className="icon-content">
        {!!notify && notify !== "" && <span className="notify">{notify}</span>}
        <Icon icon={icon}></Icon>
      </div>
      <span>{label}</span>
    </div>
  );
};

export default ItemMenu;
