import React from "react";
import {FormElements} from "../../../../../shared/ui/forms/FormElements";
import {templateFormOxygen
} from "./templateForm";

export function TemplateOxigen({formik}) {
	return (
		<>
			<FormElements
				structure={templateFormOxygen}
				formik={formik}></FormElements>
		</>
	);
}
