import React from "react";
import HeadingH4 from "../../shared/ui/headings/headingH4/HeadingH4";
import Icon from "../../shared/ui/icon/Icon";
import InputNumber from "../../shared/ui/forms/number/InputNumber";
export function ProductCartEdit({
  product,
  quantity,
  changeValueHandle,
  keyDownHandler,
  upValue,
  downValue,
  deleteProductCart,
}) {
  return (
    <div className="d-flex align-items-center">
      {product.specifiedQuantity && (
        <>
          <HeadingH4 className="m-0 mx-2">Q.tà </HeadingH4>
          <div style={{ width: "80px" }}>
            <InputNumber
              data-test="product-change-quantity"
              ele={{ name: product.id, format: "number", min: 1 }}
              value={quantity || ""}
              onChange={changeValueHandle}
              onKeyDown={keyDownHandler}
              upHandler={upValue}
              downHandler={downValue}
            />
          </div>
        </>
      )}
      {product?.price && (
        <HeadingH4
          className="m-0 mx-2 wordNoWrap dark"
          data-test="product-cart-price"
        >
          <span>€ {product?.price.toFixed(2)}</span>
        </HeadingH4>
      )}
      {deleteProductCart && (
        <Icon
          data-test="product-delete"
          icon="trash"
          colorStyle="#00A0E1"
          clickAction={() => deleteProductCart(product.id)}
        />
      )}
    </div>
  );
}
