import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: { n; manual; stop } = {
	n: 0,
	manual: false,
	stop: false,
};

export const loadSlice = createSlice({
	name: "load",
	initialState,
	reducers: {
    incrementN: (state) => {
			state.n = state.n + 1;
		},
		decrementN: (state) => {
			state.n = state.n - 1;
		},
		setLoadManual: (state, action) => {
			state.manual = action.payload;
		},
		setStopLoader: (state, action) => {
			state.stop = action.payload;
		},
	},
});

export const { incrementN, decrementN, setLoadManual, setStopLoader } = loadSlice.actions;

export const selectActiveLoad = (state) => !!state.load.n;
export const selectActiveManualLoad = (state) => state.load.manual;
export const selectStopLoad = (state) => state.load.stop;

export default loadSlice.reducer;
