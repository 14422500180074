import React from "react";
import HeadingH4 from "../../shared/ui/headings/headingH4/HeadingH4";

export function SummaryLightOrder({ products = [], isPharmacy }) {
	return (
		<div>
			<HeadingH4>{isPharmacy ? "Prodotti" : "Prescrizione"}</HeadingH4>
			<div>
				{products.map((ele) => (
					<div key={ele.id} className="mb-3">
						– {ele.title}
					</div>
				))}
			</div>
		</div>
	);
}
