import React from "react";
import Label from "../label/Label";

const BoxLabel = ({ label, className, children }) => {
	return (
		<div className={`box-label ${className}`}>
			{label && <Label label={label} className="title-box" />}
			{children}
		</div>
	);
};

export default BoxLabel;
