import React from "react";
import HeadingH3 from "../../../../shared/ui/headings/headingH3/HeadingH3";

export function TotalOrder({ total }) {
	return (
		<HeadingH3 className="link">
			<span className="mr-5">TOT. Ordine</span>
			<span className="mr-l">€{total.toFixed(2)}</span>
		</HeadingH3>
	);
}
