import React from "react";
import { Col, Row } from "react-bootstrap";

export default function TitleCard({ title, subtitle }) {
	return (
		<Row className="w-100 p-3">
			<Col xs="12" className="text-center">
				<h2>{title}</h2>
			</Col>
			<Col xs="12" className="text-center">
				<h6>{subtitle}</h6>
			</Col>
		</Row>
	);
}
