import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import FiltersCol from "../../shared/design/history/FilterSidebar/FiltersCol";
import HistoryDetail from "./components/HistoryDetail";
import { prevDayDate } from "../../utils/dateUtils";

export function HistoricalPage({ history }) {
	const formik = useFormik({
		initialValues: {
			//dateBy: prevDayDate(new Date().getTime()).toISOString(),
		},
		onSubmit: (values) => {},
	});
	const heightContent = `calc(100vh - 180px)`;

	return (
		<div className="row-history">
			<div className="col-filters">
				<Card className="mb-0">
					<CardBody style={{ height: heightContent, overflow: "auto" }}>
						<Row>
							<Col xs="12">
								<FiltersCol formik={formik} />
							</Col>
						</Row>
					</CardBody>
				</Card>
			</div>
			<div className="col-history">
				<HistoryDetail filters={formik.values} />
			</div>
		</div>
	);
}
