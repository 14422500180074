import React from "react";
import Icon from "../../icon/Icon";
import { InputCustom } from "../input/InputCustom";

const InputNumber = ({
  ele,
  value,
  onChange,
  upHandler,
  downHandler,
  ...rest
}) => {
  return (
    <InputCustom
      ele={ele}
      val={value}
      changeValue={onChange}
      rightTemplate={
        <div>
          <Icon
            icon="chevron-top"
            size="8px"
            className="mb-2"
            data-test="input-up"
            clickAction={upHandler}
          />
          <Icon
            icon="chevron-bottom"
            size="8px"
            clickAction={downHandler}
            data-test="input-down"
          />
        </div>
      }
      {...rest}
    />
  );
};

export default InputNumber;
