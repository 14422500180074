import React from "react";
const HeadingH4 = ({ children, className, ...rest }) => {
	return (
		<h3 className={`heading-4 ${className}`} {...rest}>
			{children}
		</h3>
	);
};

export default HeadingH4;
