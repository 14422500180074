export const optionDelivery = {
	pharmacy: {
		label: "Farmacia",
		value: "pharmacy",
	},
	patient: {
		label: "A domicilio",
		value: "patient",
	},
};

export const formStructureConsegnaRow = [
	{
		name: "delivery",
		label: "",
		type: "radio",
		format: "",
		options: [optionDelivery.pharmacy, optionDelivery.patient],
		col: { xs: "12", sm: "6", lg: "6" },
	},
];
export const formStructureIndirizzoConsegnaRow = [
	{
		name: "patient.domicile.street",
		label: "Via/Piazza/Strada",
		type: "input",
		col: { xs: "12", sm: "6", lg: "4", xl: "3" },
	},
	{
		name: "patient.domicile.number",
		label: "Numero",
		type: "input",
		col: { xs: "4", sm: "2", lg: "2", xl: "1" },
	},
	{
		name: "patient.domicile.internal",
		label: "Interno",
		type: "input",
		col: { xs: "4", sm: "2", lg: "2", xl: "1" },
	},
	{
		name: "patient.domicile.cap",
		label: "CAP",
		type: "input",
		col: { xs: "4", sm: "2", lg: "2", xl: "1" },
	},
	{
		name: "patient.domicile.city",
		label: "Città",
		type: "input",
		col: { xs: "12", sm: "6", lg: "4", xl: "3" },
	},
	{
		name: "patient.domicile.province",
		label: "Provincia",
		type: "input",
		col: { xs: "12", sm: "3", lg: "2", xl: "1" },
	},
];
export const formStructureContattiRow = [
	{
		name: "patient.phone",
		label: "Telefono",
		type: "input",
		col: { xs: "12", sm: "6", md: "4", lg: "4", xl: "3" },
	},
	{
		name: "patient.mobile",
		label: "Cellulare",
		type: "input",
		col: { xs: "12", sm: "6", md: "4", lg: "4", xl: "3" },
	},
	{
		name: "patient.email",
		label: "Email",
		type: "input",
		col: { xs: "12", sm: "6", md: "4", lg: "4", xl: "3" },
	},
];

export const formStructureNominativeRow = [
	{
		name: "patient.surname",
		label: "Cognome",
		type: "input",
		col: { xs: "12", sm: "6", md: "3", lg: "3", xl: "3" },
	},
	{
		name: "patient.name",
		label: "Nome",
		type: "input",
		col: { xs: "12", sm: "6", md: "3", lg: "3", xl: "3" },
	},
	{
		name: "patient.cf",
		label: "Codice Fiscale",
		type: "input",
		col: { xs: "12", sm: "6", md: "4", lg: "3", xl: "3" },
	},
];
export const formStructureNominativeRowWithTelephone = [
	...formStructureNominativeRow,
	{
		name: "patient.phone",
		label: "Telefono",
		type: "input",
		col: { xs: "12", sm: "6", md: "4", lg: "4", xl: "3" },
	},
];

export const formStructureOpzioniConsegnaRow = [
	{
		name: "delivery",
		label: "",
		type: "radio",
		format: "",
		options: [
			{
				label: "Farmacia",
				value: "farmacia",
				col: { xs: "12", sm: "12", lg: "12" },
			},
			{
				label: "A domicilio",
				value: "domicilio",
				col: { xs: "12", sm: "12", lg: "12" },
			},
		],
		col: { xs: "12", sm: "12", lg: "12" },
	},
];
export const formStructureNoteRow = [
	{
		name: "note",
		label: "",
		type: "textarea",
		col: { xs: "12" },
	},
];
