import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import it from 'date-fns/locale/it';
registerLocale('it', it)

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  // if (touched && !errors) {
  //   classes.push("is-valid");
  // }

  return classes.join(" ");
};

const handleDateChangeRaw = (e) => {
  e.preventDefault();
}

export function DatePickerField({ ...props }) {
  const { /*setFieldValue,*/ errors, touched } = useFormikContext();
  const [field] = useField(props);
  return (
    <>
      {props.label && <label>{props.label}</label>}
      <DatePicker
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        style={{ width: "100%" }}
        {...field}
        {...props}
        locale="it"
        placeholderText="Seleziona una data"
        dateFormat="dd/MM/yyyy"
        selected={(field.value && new Date(field.value)) || null}
        // onChange={val => {
        //   setFieldValue(field.name, val);
        // }}
        isClearable
        onChange={props.onChange}
        onChangeRaw={handleDateChangeRaw}
      />
      {
        // errors[field.name] && touched[field.name] ? (
        //   <div className="invalid-datepicker-feedback">
        //     {errors[field.name].toString()}
        //   </div>
        // ) :
        //   (
        //     <div className="feedback">
        //       formato 'mm/dd/yyyy'
        //       {/* Per favore inserisci <b>{props.label}</b> nel formato 'mm/dd/yyyy' */}
        //     </div>
        //   )
      }
    </>
  );
}
