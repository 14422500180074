import React, { useMemo, useLayoutEffect, useEffect } from "react";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import {
	getBreadcrumbsAndTitle,
	useSubheader,
} from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { selectSelectedCustomer } from "../../../../redux/customer/customerSlice";
import { customerType } from "../../../../redux/customer/customerData";
import { selectUser } from "../../../../app/modules/Auth/_redux/authRedux";

export function SubHeader() {
	const uiService = useHtmlClassService();
	const location = useLocation();
	const subheader = useSubheader();

	const layoutProps = useMemo(() => {
		return {
			config: uiService.config,
			subheaderMobileToggle: objectPath.get(
				uiService.config,
				"subheader.mobile-toggle"
			),
			subheaderCssClasses: uiService.getClasses("subheader", true),
			subheaderContainerCssClasses: uiService.getClasses(
				"subheader_container",
				true
			),
		};
	}, [uiService]);

	useLayoutEffect(() => {
		//const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
		const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
		//subheader.setBreadcrumbs(/*aside.breadcrumbs ||*/ header.breadcrumbs);
		subheader.setTitle(/*aside.title ||*/ header.title);
		// eslint-disable-next-line
	}, [location.pathname]);

	// Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
	useEffect(() => {}, [subheader]);

	const { currentState } = useSelector(
		(state) => ({ currentState: state.users }),
		shallowEqual
	);

	const user = useSelector(selectUser);
	const selectedCustomer = useSelector(selectSelectedCustomer);

	return (
		<div
			id="kt_subheader"
			className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}>
			<div
				className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}>
				<h6 className="m-0">
					{user.role !== customerType.bo ? selectedCustomer?.name : ""}
				</h6>

				{/* Toolbar */}
				<div className="d-flex align-items-center">
					{/*<h5 className="text-dark font-weight-bold my-2 mr-5">*/}
					{/*  {*/}
					{/*    selectedCustomer*/}
					{/*      ? selectedCustomer.CustomerName*/}
					{/*      : customersList.length > 0*/}
					{/*        ? customersList.filter(e => { return e.CustomerId === localStorage.getItem("customerId") })[0].CustomerName*/}
					{/*        : ""*/}
					{/*  }*/}
					{/*</h5>*/}
					{/* <a href="#" className="btn btn-light btn-sm font-weight-bold" id="kt_dashboard_daterangepicker"
               data-toggle="tooltip" title="Select dashboard daterange" data-placement="left">
              <span className="text-muted font-weight-bold mr-2" id="kt_dashboard_daterangepicker_title">Today</span>
              <span className="text-primary font-weight-bold" id="kt_dashboard_daterangepicker_date">Aug 16</span>
            </a> */}
					{/* <BreadCrumbs items={subheader.breadcrumbs} /> */}
					{/* <QuickActions/> */}
				</div>
				{/* Info */}
				<div className="d-flex align-items-center">
					{layoutProps.subheaderMobileToggle && (
						<button
							className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
							id="kt_subheader_mobile_toggle">
							<span />
						</button>
					)}
					<div className="d-flex align-items-baseline mr-5">
						<h5 className="text-dark font-weight-bold my-2 mr-5">
							<>{subheader.title}</>
							{/*<small></small>*/}
						</h5>
					</div>
					{/* <BreadCrumbs items={subheader.breadcrumbs} /> */}
				</div>
			</div>
		</div>
	);
}
