export const customerType = {
	prescriber: "prescriber",
	pharmacy: "pharmacy",
	bo: "bo",
	rdomanager: "rdomanager"
};
export const customerTypeName = {
	[customerType.prescriber]: "Prescrittore",
	[customerType.pharmacy]: "Farmacia",
	[customerType.bo]: "bo",
};
