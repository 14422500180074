import React from "react";
import ToastMsg from "../../../toast/ToastMsg";

const styleError = {
	style: { color: "#99A0A0", bgColor: "#fff" },
	icon: { color: "#99A0A0#", ico: "lock" },
};

const ToastPermission = ({ className, title, body, closeAction, ...rest }) => {
	return (
		<ToastMsg
			className="shared-toastPermission"
			toastStyle={styleError}
			title={title}
			body={body}
			closeAction={() => closeAction && closeAction()}></ToastMsg>
	);
};

export default ToastPermission;
