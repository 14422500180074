import React from "react";

export function UseTemplateGenFar(deliveries = []) {
	const templateGenerateDelivery = (ele) => {
		return (
			<span>
				<span className="mr-3">{ele.title}</span>
				{ele.dateDelivery && <u className="mr-3">{ele.dateDelivery}</u>}
				{ele?.price && <b>+ € {ele.price.toFixed(2)}</b>}
			</span>
		);
	};

	const formStructureDeliveryOptionsRow = [
		{
			name: "deliveryType",
			label: "",
			format: "",
			type: "radio",
			options: deliveries.map((ele) => {
				return {
					label: templateGenerateDelivery(ele),
					value: ele.id.toString(),
					col: { xs: "12", sm: "12", lg: "12" },
				};
			}),
			col: { xs: "12", sm: "12", lg: "12" },
		},
	];
	return {
		formStructureDeliveryOptionsRow,
	};
}
