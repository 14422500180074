import React from "react";
import HeadingH4 from "../../shared/ui/headings/headingH4/HeadingH4";

export function ProductCartRead({ product }) {
	return (
		<div className="d-flex">
			{product?.specifiedQuantity && !!product?.quantity && (
				<HeadingH4 className="mr-2">Q.tà {product?.quantity}</HeadingH4>
			)}
			{product?.specifiedQuantity && !!product?.price && (
				<div className="d-flex">
					<HeadingH4 className="mr-5">
						<span className="mr-2">x</span> € {product?.price.toFixed(2)}
					</HeadingH4>
					<HeadingH4 className="ml-5">
						<span className="mr-2">TOT.</span> €
						{(product?.price * product?.quantity).toFixed(2)}
					</HeadingH4>
				</div>
			)}
		</div>
	);
}
