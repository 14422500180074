export const typeData = {
  activation: {
    name: "activation",
    label: "Attivazione",
  },
  delivery: {
    name: "delivery",
    label: "Consegna",
  },
  renewal: {
    name: "renewal",
    label: "Rinnovo",
  },
  change: {
    name: "variation",
    label: "Variazione",
  },
  suspension: {
    name: "suspension",
    label: "Sospensione",
  },
};
