import React from "react";
import {SelectProductForm} from "./SelectProductForm";
import {SelectProductButtons} from "./SelectProductButtons";
import {useFormik} from "formik";
import {validationTemplatesForm} from "./templates/templateValidation";


export function SelectProductFullForm({product, changeQuantityProduct, changeRouteProducts, addToCart, createOrder, isInCart, isPharmacy}) {
	const formik = useFormik({
		initialValues: product?.detail || {},
		validationSchema: validationTemplatesForm[product?.template],
		onSubmit: (values) => {},
	});
	const isValid = (callback) => {
		const valid = validationTemplatesForm[product?.template].isValidSync(
			formik.values
		);
		formik.submitForm();
		if (valid) {
			callback && callback();
		}
	};
	const addToCartHandler = () => {
		const callback = () => {
			addToCart(formik.values)
		}
		isValid(callback)
	}
	const createOrderHandler = () => {
		const callback = () => {
			createOrder(formik.values)
		}
		isValid(callback)
	}

	return (
		<>
			<SelectProductForm
				product={product}
				changeQuantityProduct={changeQuantityProduct}
				formik={formik}
			/>
			<SelectProductButtons
				changeRouteProducts={changeRouteProducts}
				addToCart={addToCartHandler}
				createOrder={createOrderHandler}
				isInCart={isInCart}
				isPharmacy={isPharmacy}
			/>
		</>
	);
}
