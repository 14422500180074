import { useSelector } from "react-redux";

import {selectPermissions} from "../../modules/Auth/_redux/authRedux";
import {issetPermissions} from "./permissionManagement";

export function usePermissions() {
  const permissions = useSelector(selectPermissions);
  const issetPermission = (permissionList: string[], or: boolean = false) => {
    return issetPermissions(permissions, permissionList, or);
  };
  return {
    issetPermission,
  };
}
