import React from "react";
import { Col, FormControl } from "react-bootstrap";
import { FormGroupCustom } from "./FormGroupCustom";
import { InputCustom } from "./input/InputCustom";
import { RadioForm } from "./radio/RadioForm";
import { dataFormType } from "./dataFormType";
import { SelectForm } from "./select/SelectForm";
import Error from "./error/Error";
import TextArea from "./textArea/TextArea";
import { CheckboxForm } from "./checkbox/CheckboxForm";
import { CheckboxSingleForm } from "./checkbox/CheckboxSingleForm";

export function FormElement({ ele, formik, changeValue }) {
  return (
    <Col key={ele.name} {...(ele.col || {})}>
      <FormGroupCustom label={ele.label}>
        {ele.type === dataFormType.input && (
          <InputCustom
            rightTemplate={ele.rightTemplate}
            ele={ele}
            val={eval(`formik.values?.${ele.name.split(".").join("?.")}`) || ""}
            formik={formik}
            changeValue={(val) =>
              changeValue
                ? changeValue(ele.name, val)
                : formik.setFieldValue(ele.name, val)
            }
          />
        )}
        {ele.type === dataFormType.radio && (
          <RadioForm
            ele={ele}
            formik={formik}
            changeValue={changeValue}
          ></RadioForm>
        )}
        {ele.type === dataFormType.select && (
          <SelectForm ele={ele} formik={formik}></SelectForm>
        )}
        {ele.type === dataFormType.checkbox && (
          <CheckboxForm ele={ele} formik={formik}></CheckboxForm>
        )}
        {ele.type === dataFormType.checkboxSingle && (
          <CheckboxSingleForm ele={ele} formik={formik}></CheckboxSingleForm>
        )}
        {ele.type === dataFormType.textarea && (
          <TextArea
            ele={ele}
            value={eval(`formik.values?.${ele.name.split(".").join("?.")}`)}
            formik={formik}
          ></TextArea>
        )}
        {!!formik.submitCount &&
          !!formik.errors &&
          eval(`formik.errors?.${ele.name.split(".").join("?.")}`) && (
            <Error>
              {eval(`formik.errors?.${ele.name.split(".").join("?.")}`)}
            </Error>
          )}
      </FormGroupCustom>
    </Col>
  );
}
