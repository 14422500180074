import React, { useEffect, useState } from "react";
import { getContainersAction } from "../../../redux/container/containerActions";
import { useDispatch, useSelector } from "react-redux";
import { selectContainers } from "../../../redux/container/containerSlice";
import { TableContainers } from "./components/table/TableContainers";
import { Col, Row } from "react-bootstrap";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../_metronic/_partials/controls";
import { typeViewContainer } from "./components/table/containersTableData";
import { SwitchViewContainers } from "./components/view/SwitchViewContainers";
import IconBtn from "../../shared/ui/buttons/icon-button/IconButton";
import { containerPharmacyCommunications } from "../../../redux/container/containerCrud";
import { selectSelectedCustomer } from "../../../redux/customer/customerSlice";
import {
	setErrorTypeAction,
	setSuccessErrorAction,
} from "../../../redux/errors/errorsActions";
import { resetModal, setModal } from "../../shared/redux/modal/modalSlice";

export function Containers() {
	const containers = useSelector(selectContainers);
	const customer = useSelector(selectSelectedCustomer);
	const [selectContainer, setSelectContainer] = useState();
	const [showView, setShowView] = useState();
	const dispatch = useDispatch();
	const setSelectedContainerHandler = (ele) => {
		setSelectContainer({ ...ele });
		setShowView(typeViewContainer.trace);
	};
	const resetShowTrace = () => {
		setShowView(undefined);
		setSelectContainer(undefined);
	};
	const setCollect = (ele) => {
		setSelectContainer({ ...ele });
		setShowView(typeViewContainer.collect);
	};
	useEffect(() => {
		dispatch(getContainersAction());
	}, []);

	const buttonList = [
		{
			label: `Annulla`,
			action: "cancelCommunicationPharmacy",
			type: "secondary",
			dataType: {},
		},
		{
			label: `Conferma`,
			action: "communicationsPharmacy",
			type: "primary",
			dataType: {},
		},
	];
	const communicationsPharmacyModal = () => {
		dispatch(
			setModal(
				buttonList,
				{
					title: `Confermi di voler richiedere il ritiro in farmacia?`,
				},
				{ cancelCommunicationPharmacy, communicationsPharmacy }
			)
		);
	};
	const communicationsPharmacy = () => {
		containerPharmacyCommunications(customer.id)
			.then((res) => {
				dispatch(
					setSuccessErrorAction(
						"Comunicazione ritiro in farmacia avvenuta con successo"
					)
				);
			})
			.catch(() =>
				dispatch(
					setErrorTypeAction(
						"Errore nell'invio della comunicazione di ritiro bombole"
					)
				)
			);
		dispatch(resetModal());
	};
	const cancelCommunicationPharmacy = () => {
		dispatch(resetModal());
	};

	return (
		<Row>
			<Col xs="12">
				<Card>
					<CardHeader
						title={
							!showView
								? "Stato magazzino"
								: showView === typeViewContainer.trace
								? "Traccia Bombola"
								: showView === typeViewContainer.collect
								? "Richiesta di ritiro presso domicilio paziente"
								: ""
						}
					/>
					<CardBody>
						<Row className="mb-5 justify-content-center">
							<Col xs="12">
								<div>
									{!showView ? (
										<>
											<Row
												className="justify-content-end"
												style={{ marginTop: "-20px" }}>
												<IconBtn
													icon="pharmacy"
													className="mr-3"
													onClick={communicationsPharmacyModal}>
													Richiesta ritiro in farmacia
												</IconBtn>
												<IconBtn
													icon="track"
													sizeBtn="16px"
													onClick={() => setShowView(typeViewContainer.trace)}>
													Traccia
												</IconBtn>
											</Row>
											<TableContainers
												containers={containers}
												setSelectedContainerHandler={
													setSelectedContainerHandler
												}
												setCollect={setCollect}
											/>
										</>
									) : null}
									{showView && (
										<SwitchViewContainers
											resetShowTrace={resetShowTrace}
											selectContainer={selectContainer}
											showView={showView}
										/>
									)}
								</div>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Col>
		</Row>
	);
}
