import React from "react";
import {FormElements} from "../../../../../shared/ui/forms/FormElements";
import {templateFormNote} from "./templateForm";

export function TemplateNote({formik}) {
	return (
		<>
			{/*<FormElements*/}
			{/*	structure={templateFormNote}*/}
			{/*	formik={formik}></FormElements>*/}
		</>
	);
}
