export const columnsContainers = [
	{
		dataField: "codeProduct",
		text: "Prodotto",
		classes: "text-left pr-0",
		headerClasses: "text-left pr-3",
		style: {
			minWidth: "100px",
		},
	},
	{
		dataField: "descriptionSummary",
		text: "",
		classes: "text-left pr-0",
		headerClasses: "text-left pr-3",
		style: {
			minWidth: "100px",
		},
	},
	{
		dataField: "serial",
		text: "Seriale",
		classes: "text-left pr-0",
		headerClasses: "text-left pr-3",
		style: {
			minWidth: "100px",
		},
	},
];

export const typeViewContainer = {
	trace: 'trace',
	collect: 'collect'
}
