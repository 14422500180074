import React from "react";
import { Row } from "react-bootstrap";
import { FormElement } from "./FormElement";

export function FormElements({ structure, formik, changeValue, rightTemplate, style }) {
	return (
		<Row className="align-items-center" style={style}>
			{structure.map((ele) => (
				<FormElement key={ele.name} ele={ele} formik={formik} changeValue={changeValue}/>
			))}
			{rightTemplate && rightTemplate}
		</Row>
	);
}
