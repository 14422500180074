import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import {Card, CardBody, CardHeader} from "../../../../../_metronic/_partials/controls";
import WizardOrder from "../../../../shared/app/wizard_order/WizardOrder";
import {nameSteps} from "../../../../shared/app/wizard_order/wizardOrderData";
import {SelectProductHeader} from "./SelectProductHeader";
import {SelectProductFullForm} from "./SelectProductFullForm";

export function SelectProductComponent({isPharmacy, patient, product, changeQuantityProduct, changeRouteProducts, addToCart, createOrder, isInCart  }) {


	return (
		<Container>
			<Row>
				<Col xs="12">
					<WizardOrder selectedStep={nameSteps.detail} />
					<Card>
						<CardHeader>
							<SelectProductHeader
								isPharmacy={isPharmacy}
								patient={patient}
							/>
						</CardHeader>
						<CardBody>
							<SelectProductFullForm
								product={product}
								changeQuantityProduct={changeQuantityProduct}
								changeRouteProducts={changeRouteProducts}
								addToCart={addToCart}
								createOrder={createOrder}
								isInCart={isInCart}
								isPharmacy={isPharmacy}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}
