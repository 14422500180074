import React from "react";
import {Trace} from "./Trace";
import {typeViewContainer} from "../table/containersTableData";
import {Collect} from "./Collect";


export function SwitchViewContainers({resetShowTrace, selectContainer, showView}) {
	const listView = {
		[typeViewContainer.trace] : <Trace resetShowTrace={resetShowTrace}	container={selectContainer}	/>,
		[typeViewContainer.collect] : <Collect container={selectContainer} resetShow={resetShowTrace}></Collect>
	}
	return (
		<>
			{listView[showView]}
		</>
	);
}
