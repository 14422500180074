import React from "react";
import Hr from "../../../../shared/ui/lineBrake/Hr";
import { RowTotal } from "../rowTotal/RowTotal";
import { HeaderDetailOrder } from "./HeaderDetailOrder";
import { ProductListOrder } from "./ProductListOrder";

export function OrderDetailComponent({
	orderDetail,
	order,
	showPatientInfo,
	changeStateOrder,
	updateOrderHandler,
	 setOrderInEdit
}) {
	return (
		<>
			<HeaderDetailOrder
				order={order}
				orderDetail={orderDetail}
				changeStateOrder={changeStateOrder}
				showPatientInfo={showPatientInfo}
				updateOrderHandler={updateOrderHandler}
				setOrderInEdit={setOrderInEdit}
			/>
			<Hr />
			<ProductListOrder order={order} showBtnAction={true} />
			<Hr />
			<RowTotal
				products={order.productCart || order.products}
				orderDetail={orderDetail}
			/>
		</>
	);
}
