import React from "react";
import { Col, InputGroup, Row } from "react-bootstrap";

export function CheckboxForm({ ele, formik }) {
	const changeData = (event) => {
		const oldValue = formik.values[ele.name] || [];
		const value = event.target.value;
		formik.setFieldValue(
			ele.name,
			oldValue.includes(value)
				? oldValue.filter((ele) => ele.toString() !== value)
				: [...oldValue, value]
		);
	};
	return (
		<Row
			className={`${ele.display ? ele.display : ""} ${
				ele.className ? ele.className : ""
			}`}>
			{ele.options.map((opt) => (
				<Col xs="auto" {...(opt.col || {})} key={opt.label}>
					<InputGroup className="shared-checkbox">
						<InputGroup.Checkbox
							checked={formik.values[ele.name]
								?.toString()
								?.includes(opt.value.toString())}
							className="checkbox-sh"
							value={opt.value}
							name={ele.name}
							onChange={changeData}>
						</InputGroup.Checkbox>
						<label>{opt.label}</label>
					</InputGroup>
				</Col>
			))}
		</Row>
	);
}
