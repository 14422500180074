import React from "react";

const Icon = ({
	icon,
	size = "",
	color = "",
	colorStyle,
	children,
	className = "",
	disabled = false,
	label = "",
	clickAction,
	...rest
}) => {
	const isDisabled = disabled ? "disabled" : "";
	const styleColor = { "--color-icon-var": colorStyle };
	const styleSize = { fontSize: size };
	return (
		<div
			onClick={(event) => !disabled && clickAction && clickAction(event)}
			className={`shared-icon ${className} ${
				clickAction && !disabled ? "pointer" : ""
			}`}
			{...rest}>
			<span
				style={{ ...styleColor, ...styleSize }}
				className={`icon icon-${icon} ${isDisabled}`}></span>
			{label ? (
				<span className={`${isDisabled} iconLabel`} style={styleColor}>
					{label}
				</span>
			) : null}
		</div>
	);
};

export default Icon;
