import React from "react";
import { Col, Row } from "react-bootstrap";
import LabelValue from "../../../../../../shared/ui/labelValue/LabelValue";
import { templateFormNote } from "../templateForm";

export function TemplateNoteRead({ data }) {
	return (
		<Row>
			{templateFormNote.map((field, i) => (
				<Col
					key={i}
					xs={field.col.xs}
					sm={field.col.sm}
					md={field.col.md}
					lg={field.col.lg}
					className={`${field?.className || ""} mb-3`}>
					<small className="mr-2">{field.label}</small>
					<br/>
					<span>{(data && data[field.name]) || "-"}</span>
				</Col>
			))}
		</Row>
	);
}
