import React from "react";
import Button from "../button/Button";
import Icon from "../../icon/Icon";
import Caption from "../../headings/caption/Caption";
import I from "../../icon/I";

const IconBtn = ({
	children,
	className,
	sizeBtn,
	variant = "primary",

	type = "submit",
	icon,
	isFontAwesome = false,
	...rest
}) => {
	return (
		<div className={`sh-iconBtn ${className}`} {...rest}>
			<Caption className="d-flex align-items-center">
				{isFontAwesome ? (
					<I icon={icon} size={sizeBtn} className="mr-2"/>
				) : (
					<Icon icon={icon} size={sizeBtn} />
				)}
				{children}
			</Caption>
		</div>
	);
};

export default IconBtn;
