import React from "react";
import Caption from "../headings/caption/Caption";
import HeadingH3 from "../headings/headingH3/HeadingH3";
import Icon from "../icon/Icon";
import Hr from "../lineBrake/Hr";
import { useDispatch } from "react-redux";
import { useRoute } from "../../../utils/useRoute";
import * as routeName from "../../../routes/routeNameAuthType";
import { setSelectedProduct } from "../../../../redux/products/productsSlice";
import HeadingH4 from "../headings/headingH4/HeadingH4";

const ProductCard = ({ product, disabled = false }) => {
	const dispatch = useDispatch();
	const { changeRoute } = useRoute();
	const addToCart = () => {
		dispatch(setSelectedProduct(product));
		changeRoute(routeName.selectProduct);
	};
	return (
		<div className={`shared-product-card ${disabled && "disabled"} `}>
			<div className="thumb-info">
				<div className="thumb">
					{product?.img && <img src={product?.img} width="100%" />}
				</div>
			</div>
			<div className="info-product">
				<div className="head-info-product">
					<div className="title-product">
						<HeadingH3>{product?.title}</HeadingH3>
						{/* <Hr /> */}
						<Caption className="lg">{product?.descriptionSummary}</Caption>
						<Caption className="mt-3 primary">{product?.description}</Caption>
						<span className="mt-3">Cod. {product?.codeERP}</span>
					</div>
					<div>
						{!disabled && (
							<Icon
								icon="arrow-right"
								colorStyle="#00A0E1"
								clickAction={addToCart}
							/>
						)}
					</div>
				</div>
				<div className="footer-info-product">
					{product?.price && (
						<Caption className="primary lg">
							€ {product.price.toFixed(2)}
						</Caption>
					)}
				</div>
			</div>
		</div>
	);
};

export default ProductCard;
