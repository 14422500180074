import React from "react";
import { Col, Row } from "react-bootstrap";
import WizardStep from "../step/WizardStep";

const WizardGroup = ({ listStep }) => {
	return (
		<Row>
			<Col xs="12">
				<div className={`shared-wizardGroup`}>
					{listStep.map((step, i) => (
						<WizardStep
							key={i}
							label={step.label}
							index={i}
							isActive={step.isActive}
						/>
					))}
				</div>
			</Col>
		</Row>
	);
};

export default WizardGroup;
