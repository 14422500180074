import React, { useEffect, useState } from "react";
import Caption from "../../shared/ui/headings/caption/Caption";
import HeadingH3 from "../../shared/ui/headings/headingH3/HeadingH3";
import Hr from "../../shared/ui/lineBrake/Hr";
import { ProductCartRead } from "./ProductCartRead";
import { ProductCartEdit } from "./ProductCartEdit";
import { isEmptyString } from "../../utils/stringUtils";
import HeadingH4 from "../../shared/ui/headings/headingH4/HeadingH4";
let timeoutAction;
export function ProductCart({
  product,
  changeValue,
  clickTitle,
  changeQuantity,
  delay = 2000,
  deleteProductCart,
  isEdit = true,
}) {
  const [quantity, setQuantity] = useState();
  useEffect(() => {
    setQuantityAction(product.quantity);
  }, [product]);
  const clearTime = () => {
    timeoutAction && clearTimeout(timeoutAction);
  };
  const setQuantityAction = (value) => {
    setQuantity(value);
    changeQuantity && changeQuantity(value);
  };
  const actionModify = (value) => {
    const val = isEmptyString(value) ? product.quantity : value;
    val === "0" ? deleteProductCart(product.id) : changeValue(val);
  };
  const setQuantityAndActionModify = (value) => {
    setQuantityAction(value);
    actionModify(value);
  };
  const changeValueHandle = (value) => {
    setQuantityAction(value);
    clearTime();
    timeoutAction = setTimeout(() => {
      actionModify(value);
    }, delay);
  };

  const keyDownHandler = (event) => {
    if (event.which === 13) {
      event.preventDefault();
      clearTime();
      actionModify(quantity);
    }
  };
  const upValue = () => {
    quantity
      ? setQuantityAndActionModify(+quantity + 1)
      : setQuantityAndActionModify(1);
  };
  const downValue = () => {
    if (quantity > 1) setQuantityAndActionModify(+quantity - 1);
  };

  return (
    <>
      <div className="shared-product-cart" data-test="product-cart">
        <div className="thumb-info">
          <div className="thumb" data-test="product-cart-image">
            {product?.img && <img src={product?.img} width="100%" />}
          </div>
        </div>
        <div data-test="info-product" className="info-product">
          <div className="head-info-product">
            <div className="title-product">
              <HeadingH3
                data-test="product-cart-title"
                onClick={() => clickTitle && clickTitle()}
                className={clickTitle ? "pointer" : "auto"}
              >
                {product?.title}
              </HeadingH3>
              {/* <Hr /> */}
              <Caption
                className="lg"
                data-test="product-cart-description-summary"
              >
                {product?.descriptionSummary}
              </Caption>
              <Caption
                className="mt-3 primary"
                data-test="product-cart-description"
              >
                {product?.description}
              </Caption>
              <span className="mt-3" data-test="product-cart-erp">
                Cod. {product?.codeERP}
              </span>
            </div>
            <div>
              {!isEdit ? (
                <ProductCartRead product={product} />
              ) : (
                <ProductCartEdit
                  product={product}
                  quantity={quantity}
                  changeValueHandle={changeValueHandle}
                  keyDownHandler={keyDownHandler}
                  upValue={upValue}
                  downValue={downValue}
                  deleteProductCart={deleteProductCart}
                />
              )}
            </div>
          </div>
          <div className="footer-info-product">
            {product?.specifiedQuantity && quantity && product?.price && (
              <Caption className="primary lg" data-test="product-cart-total">
                € {(product.price * quantity).toFixed(2)}
              </Caption>
            )}
          </div>
        </div>
      </div>
      {/* <Row className="align-items-center">
          <Col xs="8">{product.title}</Col>
          <Col xs="2"><FormControl
              type="number"
              name={product.id}
              onChange={(event) => {
                  event.target.value === '0' ? deleteProductCart(product.id) : changeValue(event.target.value);
              }}
              value={
                  product.quantity
              }
          /></Col>
          <Col xs="2">
              <div onClick={() => deleteProductCart(product.id)}>DEL</div>
          </Col>
      </Row> */}
    </>
  );
}
