import * as requestFromServer from "./cartCrud";
import {setErrorTypeAction} from "../errors/errorsActions";
import {addProductToCart, setCart} from "./cartSlice";

export const GetCartAction = () => (dispatch) => {
	return requestFromServer
		.getCartCall()
		.then((response) => {
			dispatch(setCart(response));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction("Errore nel caricamento del carrello"));
		});
};

export const AddProductToCartAction = (data, finalize = false, callback) => (dispatch, getState) => {
	if(getState().cart.cart.find(ele => ele.id.toString() === data.id.toString())){
		dispatch(UpdateQuantityProductToCartAction(data, data.id, callback))
	} else {
		return requestFromServer
			.addProductToCartCall(data)
			.then((response) => {
				finalize ? dispatch(addProductToCart(response)) : dispatch(GetCartAction());
				callback && callback()
			})
			.catch((error) => {
				dispatch(setErrorTypeAction("Errore nell'inserimento del prodotto a carrello"));
			});
	}

};

export const UpdateQuantityProductToCartAction = (data, idProduct, callback) => (dispatch) => {
	return requestFromServer
		.editProductCartCall(idProduct, data)
		.then((response) => {
			dispatch(GetCartAction())
			callback && callback()
			//dispatch(editProductToCart(response));
		})
		.catch((error) => {
			dispatch(GetCartAction())
			dispatch(setErrorTypeAction("Errore nell'aggiornamento del prodotto a carrello"));
		});
};

export const deleteProductByCartAction = (productId) => (dispatch) => {
	return requestFromServer
		.deleteProductByCartCall(productId)
		.then((response) => {
			dispatch(GetCartAction())
			//dispatch(deleteProductToCart(response));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction("Errore nella rimozione del prodotto dal carrello"));
		});
};
