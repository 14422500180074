/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";
import "../../../../../app/assets/css/user.css";
import { useDispatch, useSelector } from "react-redux";
import userManager from "../../../../../app/utils/oidcUserManager";
import {
	selectCustomers,
	selectSelectedCustomer,
	setSelectedCustomer,
} from "../../../../../redux/customer/customerSlice";
import { useRoute } from "../../../../../app/utils/useRoute";
import * as routeName from "../../../../../app/routes/routeNameAuthType";
import { selectIsRDO } from "../../../../../app/modules/Auth/_redux/authRedux";
import { GetAllProducts } from "../../../../../redux/products/productsActions";
import { InitCustomerAction } from "../../../../../redux/customer/customerActions";

export function QuickUser() {
	const customers = useSelector(selectCustomers);
	const isRdo = useSelector(selectIsRDO);
	const customer = useSelector(selectSelectedCustomer);
	const dispatch = useDispatch();
	const { changeRoute } = useRoute();
	const logoutClick = () => {
		const toggle = document.getElementById("kt_quick_user_toggle");
		if (toggle) {
			toggle.click();
		}
		userManager.signoutRedirect();
	};

	const { user } = useSelector((state) => state.auth);

	return (
		<div id="kt_quick_user" className="offcanvas offcanvas-right p-10">
			<div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
				<h3 className="font-weight-bold m-0">Profilo Utente</h3>
				<a
					href="#"
					className="btn btn-xs btn-icon btn-light btn-hover-primary"
					id="kt_quick_user_close">
					<i className="ki ki-close icon-xs text-muted" />
				</a>
			</div>

			<div className="offcanvas-content pr-5 mr-n5">
				<div className="d-flex h-100">
					<div className="d-flex flex-column justify-content-between h-100 w-100">
						<div>
							<a
								href="#"
								className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
								{user.fullname}
							</a>
							<div className="navi mt-2">
								<a href="#" className="navi-item">
									<span className="navi-link p-0 pb-2">
										<span className="navi-icon mr-1">
											<span className="svg-icon-lg svg-icon-primary">
												<SVG
													src={toAbsoluteUrl(
														"/media/svg/icons/Communication/Mail-notification.svg"
													)}></SVG>
											</span>
										</span>
										<span className="navi-text text-muted text-hover-primary">
											{user.email}
										</span>
									</span>
								</a>
							</div>
						</div>

						<div className="mt-4 d-flex justify-content-between">
							<a
								className="btn btn-primary mr-2"
								//id="kt_quick_user_close_button"
								// onClick={() => {
								//   window.location.href = userManager.settings.authority + "/Identity/Account/Manage";
								// }}
								href={
									userManager.settings.authority + "/Identity/Account/Manage"
								}
								target="_blank">
								Impostazioni Account
							</a>
							<button
								className="btn btn-light-primary btn-bold"
								style={{
									float: "right",
									paddingLeft: "2rem",
									paddingRight: "2rem",
								}}
								onClick={logoutClick}>
								Esci
							</button>
						</div>
					</div>
				</div>
				{/*  <div className="separator separator-dashed mt-8 mb-5" />
        {isRdo && <div>
          <ul>
            {customers.map((ele, i) => <li key={i} style={{cursor: "pointer"}} onClick={() => {
              localStorage.setItem('customerInit', ele.id)
              dispatch(setSelectedCustomer(ele))
              dispatch(InitCustomerAction());
              changeRoute(routeName.products)
            }} style={{
              cursor: 'pointer',
              backgroundColor: ele.id === customer?.id ? 'rgb(205 234 251)' : null
            }}>{ele.name} ({ele.type})</li>)}
          </ul>
        </div>} */}
			</div>
		</div>
	);
}
