import React from "react";
import { Col, Row } from "react-bootstrap";
import Caption from "../../../shared/ui/headings/caption/Caption";
import HeadingH4 from "../../../shared/ui/headings/headingH4/HeadingH4";
import I from "../../../shared/ui/icon/I";
import { optionDelivery } from "../../sample/templateGenFar/templateGenFarData";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import RowDelivery from "./summary/RowDelivery";

export function DetailDelivery({ orderDetail, order, updateDateHandler, setOrderInEdit }) {
  const isPharmacy = orderDetail?.delivery === optionDelivery.pharmacy.value;

  return (
    <>
      <HeadingH4>Dettaglio Consegna</HeadingH4>
      <div className="d-flex align-items-center w-100">
        <div>
          <span className="mr-3 ml-3 p-3">
            <I icon={faTruck} color="#686C82" size="2x" />
          </span>
        </div>
        <div className="w-100">
          <Row className="mt-3 mb-3">
            <Col xs={"12"} lg={"auto"}>
              <Row>
                <Col xs="auto">
                  <Caption className="mr-2">Consegna</Caption>
                  <span>
                {isPharmacy
                  ? optionDelivery.pharmacy.label
                  : `${orderDetail?.patient?.surname?.toUpperCase()} ${
                    orderDetail?.patient?.name
                  }`}
              </span>
                </Col>
                {!isPharmacy && (
                  <Col xs="auto">
                    <Caption className="mr-2">Indirizzo</Caption>
                    <span>
                  {orderDetail?.patient
                    ? Object.values(orderDetail?.patient?.domicile).join(" ")
                    : "-"}
                </span>
                  </Col>
                )}
                <Col xs={'12'} className={'mt-2'}>
                  <Row>
                    {orderDetail?.patient?.phone && <Col xs="auto">
                      <Caption className="mr-2">Telefono</Caption>
                      <span>
                      {orderDetail?.patient?.phone || "-"}
                     </span>
                    </Col>}
                    {orderDetail?.patient?.mobile && <Col xs="auto">
                      <Caption className="mr-2">Cellulare</Caption>
                      <span>
                      {orderDetail?.patient?.mobile || "-"}
                     </span>
                    </Col>}
                    {orderDetail?.patient?.email && <Col xs="auto">
                      <Caption className="mr-2">Email</Caption>
                      <span>
                      {orderDetail?.patient?.email || "-"}
                     </span>
                    </Col>}
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col xs={"12"} lg={"auto"} className={"ml-auto"}>
              <RowDelivery orderDetail={orderDetail} order={order} updateDateHandler={updateDateHandler}
                           setOrderInEdit={setOrderInEdit} />
            </Col>

          </Row>
          {!isPharmacy && (
            <Row className="mb-3">
              <Col xs="auto">
                <Caption className="mr-2">Tipo consegna</Caption>
                <span>
                  <span className="mr-3">
                    {orderDetail?.deliveryData?.title || "-"}
                  </span>
                  {orderDetail?.deliveryData?.dateDelivery && (
                    <u className="mr-3">
                      {orderDetail.deliveryData.dateDelivery}
                    </u>
                  )}
                  {orderDetail?.deliveryData?.price && (
                    <b>+ € {orderDetail.deliveryData.price.toFixed(2)}</b>
                  )}
                </span>
              </Col>
            </Row>
          )}
          <Row className="mb-3">
            <Col xs="auto">
              <Caption className="mr-2">Note per la consegna</Caption>
              <span data-test="note-delivery">{orderDetail?.note || "-"}</span>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
    ;
}
