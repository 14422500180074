import React from 'react';
import { Button as Btn } from 'react-bootstrap';

const Button = ({
	children,
	className = '',
	sizeBtn = 'lg',
	...rest
}) => {
	return (
		<Btn
			className={`shared-button ${className}`}
			size={sizeBtn}
			{...rest}>
			{children}
		</Btn>
	);
};

export default Button;
