import React from "react";
import { FormGroup, FormLabel } from "react-bootstrap";

export function FormGroupCustom({ label, children }) {
	return (
		<FormGroup>
			{label && <FormLabel>{label}</FormLabel>}
			{children}
		</FormGroup>
	);
}
