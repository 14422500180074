import React from "react";
import Icon from "../../icon/Icon";

const WizardStep = ({ label, isActive, index }) => {
	return (
		<div className={`shared-wizardStep ${isActive ? "active" : ""}`}>
			<div className="step-number">{index+1}</div>
			<span>{label}</span>
			<Icon className="icon-step" icon="chevron-right" size="14px" />
		</div>
	);
};

export default WizardStep;
