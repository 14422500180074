import React, {useEffect} from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormElements } from "../../../shared/ui/forms/FormElements";
import HeadingH4 from "../../../shared/ui/headings/headingH4/HeadingH4";
import {
	formStructureDiagnosiRow,
	formStructureMedicoRow,
	formStructurePrescrizioneRow,
	formStructureRichiedenteRow, validationsTemplateGenFar, validationsTemplateGenPub,
} from "./templateGenPubData";
import PrimaryButton from "../../../shared/ui/buttons/primary-button/PrimaryButton";
import SecondaryButton from "../../../shared/ui/buttons/secondary-button/SecondaryButton";
import {useFormik} from "formik";
import {useSelector} from "react-redux";
import {selectIsPharmacy} from "../../../../redux/customer/customerSlice";
import {selectUser} from "../../../modules/Auth/_redux/authRedux";
import {toEnDateString} from "../../../utils/dateUtils";

const TemplateGenPub = ({ createOrder, order, goBack }) => {
	const isPharmacy = useSelector(selectIsPharmacy);
	const user = useSelector(selectUser);
	const formik = useFormik({
		initialValues: {
			prescription: {
				date: toEnDateString(new Date().getTime())
			},
			prescriber: {
				name: user.firstname,
				surname: user.lastname
			}
		},
		validationSchema: validationsTemplateGenPub ,
		onSubmit: (values) => {
			const applicant= {
				name: user.firstname,
				surname: user.lastname,
				data: toEnDateString(new Date().getTime())
			};
			createOrder({...values, applicant})
		},
	});
	useEffect(() => {
		order && formik.setValues(order)
	}, [order])
	return (
		<div>
			<Form autocomplete="off" onSubmit={formik.handleSubmit}>
				{/*<HeadingH4>Richiedente</HeadingH4>*/}
				{/*<FormElements*/}
				{/*	structure={formStructureRichiedenteRow}*/}
				{/*	formik={formik}></FormElements>*/}
				<HeadingH4>Prescrittore</HeadingH4>
				<FormElements
					structure={formStructureMedicoRow}
					formik={formik}></FormElements>
				<HeadingH4>Prescrizione</HeadingH4>
				<FormElements
					structure={formStructurePrescrizioneRow}
					formik={formik}></FormElements>
				<HeadingH4>Diagnosi</HeadingH4>
				<FormElements
					structure={formStructureDiagnosiRow}
					formik={formik}></FormElements>
				<Row className="justify-content-end mt-3">
					<Col xs="auto">
						<SecondaryButton onClick={goBack}>{isPharmacy? "Annulla" : "Indietro"}</SecondaryButton>
					</Col>
					<Col xs="auto">
						<PrimaryButton>Finalizza</PrimaryButton>
					</Col>
				</Row>
			</Form>

		</div>
	);
};

export default TemplateGenPub;
