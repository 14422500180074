import {createSelector, createSlice} from "@reduxjs/toolkit";

const initialPatientState = {
  patient: undefined,
};


export const patientSlice = createSlice({
  name: "patient",
  initialState: initialPatientState,
  reducers: {
    setPatient: (state, action) => {
      state.patient = action.payload;
    }
  }
});

export const {setPatient} = patientSlice.actions;

export const selectPatient = (state) => state.patient.patient;


