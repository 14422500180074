import React from "react";
import { Col, Row } from "react-bootstrap";
import HeadingH4 from "../../../../shared/ui/headings/headingH4/HeadingH4";
import { TemplateSelectProductRead } from "../../../products/selectProduct/component/templates/TemplateSelectProductRead";
import ProductSummary from "../ProductSummary";
import { useSelector } from "react-redux";
import { selectIsPharmacy } from "../../../../../redux/customer/customerSlice";
import PrimaryButton from "../../../../shared/ui/buttons/primary-button/PrimaryButton";
import {ProductActionOrder} from "./ProductActionOrder";

export function ProductListOrder({ order, showBtnAction = false }) {
	const isPharmacy = useSelector(selectIsPharmacy);
	return (
		<>
			{(order?.products || order?.productCart)?.map((ord, i) => (
				<div key={i} style={{ background: "#ecf0f49c" }} className="w-100 content-product-item">
					<Row className="mb-5 p-4">
						<ProductSummary ord={ord} />
						{!!order?.actionsProduct?.length && (
							<Col xs="12">
								<ProductActionOrder actions={order?.actionsProduct} order={order}/>
							</Col>
						)}
						<Col xs="12">
							{!isPharmacy && (
								<HeadingH4 className="mt-3">Prescrizione</HeadingH4>
							)}
							<TemplateSelectProductRead
								template={ord.template}
								data={ord.detail}
							/>
						</Col>
					</Row>
					{/* <Hr /> */}
				</div>
			))}
		</>
	);
}
