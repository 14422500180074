import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { CardBody } from "../../../../_metronic/_partials/controls";
import HistoryList from "./HistoryList";
import { OrderDetailLoad } from "../../order/component/summary/OrderDetailLoad";
import { searchOrders } from "../../../../redux/order/orderCrud";
import { copyObject } from "../../../utils/objUtils";
import imgFolder from "../../../assets/img/emptyFolder.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedCustomer } from "../../../../redux/customer/customerSlice";
import { setErrorTypeAction } from "../../../../redux/errors/errorsActions";
import {setStopLoader} from "../../../shared/ui/loader/redux/loadSlice";
import {resetErrors} from "../../../../redux/errors/errorsSlice";
import { resetModal, setModal } from "../../../shared/redux/modal/modalSlice";
let polling;
let filterCall;
const HistoryDetail = ({ filters = {} }) => {
	const dispatch = useDispatch();
	const [selectedId, setSelectedId] = useState();
	const [listOrders, setListOrders] = useState([]);
	const [orderInEdit, setOrderInEdit] = useState(false)
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(0);
	const customer = useSelector(selectSelectedCustomer);
	const elementForPage = 40;
	const searchOrderPolling = (list) => {
		dispatch(setStopLoader(true));
		const copyFilters = dataByFilter(filters);
		searchOrders({
			page: 1,
			clientId: customer.id,
			elementForPage: 100,
			...copyFilters,
			lastUpdate: true
		})
			.then((res) => {
				const newOrders = res.orders;
				const orders = [...newOrders, ...filterOrdersExist(list, res.orders)]
				setListOrdersAction(orders)
				dispatch(setStopLoader(false));
			})
	}
	const filterOrdersExist = (list, newOrders) => {
		return list.filter(ele => !newOrders.map(el => el.codiceRDO).includes(ele.codiceRDO))
	}
	const timeoutPolling = (list) => {
		polling && clearTimeout(polling);
		polling = setTimeout(() => {
			searchOrderPolling(list)
		}, 60000)
	}
	const handleScroll = (event) => {
		const element = event.target;
		if (
			element.scrollHeight - element.scrollTop < element.clientHeight + 50 &&
			count > listOrders.length
		) {
			dispatch(resetErrors())
			const nextPage = page + 1;
			const copyFilters = dataByFilter(filters);
			searchOrders({
				page: nextPage,
				clientId: customer.id,
				elementForPage,
				...copyFilters,
			})
				.then((res) => {
					setPage(nextPage);
					const orders = [...filterOrdersExist(listOrders, res.orders), ...res.orders];
					setListOrdersAction(orders)
				})
				.catch((error) => {
					dispatch(setErrorTypeAction("Errore nella ricerca degli ordini"));
				});
		}
	};
	const setListOrdersAction = (orders) => {
		setListOrders(orders);
		timeoutPolling(orders)
	}
	const reloadOrder = (order) => {
		setListOrdersAction(listOrders.map((ele) => (ele.codiceRDO === order.codiceRDO ? {...ele, ...order} : ele)))
	};
	const dataByFilter = (fil) => {
		const copyFilters = copyObject(fil);
		if (!copyFilters.state?.length) {
			copyFilters.state = undefined;
		}
		if (!copyFilters.type?.length) {
			copyFilters.type = undefined;
		}
		if (!copyFilters.dateBy) {
			copyFilters.dateBy = undefined;
		}
		if (!copyFilters.dateTo) {
			copyFilters.dateTo = undefined;
		}
		return copyFilters;
	};
	useEffect(() => {
		filterCall && clearTimeout(filterCall);
		filterCall = setTimeout(() => {
			dispatch(resetErrors())
			const copyFilters = dataByFilter(filters);
			setPage(1);
			searchOrders({
				page: 1,
				elementForPage,
				clientId: customer.id,
				...copyFilters,
			})
				.then((res) => {
					setListOrdersAction(res.orders);
					setCount(res.count);
					setSelectedId(!!res.orders.length && res.orders[0]?.codiceRDO);
				})
				.catch((error) => {
					dispatch(setErrorTypeAction("Errore nella ricerca degli ordini"));
				});
		}, 500)

	}, [filters]);
	const heightContent = `calc(100vh - 180px)`;

	const buttonList = [
		{
			label: `Ho capito`,
			action: "confirmModal",
			type: "primary",
			dataType: {}
		}
	];
	const communicationsHasEditNotSaved = () => {
		dispatch(
			setModal(
				buttonList,
				{
					title: `Attenzione! Modifiche non salvate.`,
					body: `Ci sono delle modifiche non salvate. Salva o annulla per cambiare ordine.`
				},
				{ confirmModal }
			)
		);
	};
	const confirmModal = () => {
		dispatch(resetModal());
	};

	const clickOrder = (order) => {
		if(orderInEdit){
			//open modal
			communicationsHasEditNotSaved(); //TODO MASSY al click su altro ordine se c'è una modifica fa comparire la modale di avviso
		} else {
			setSelectedId(order.codiceRDO);
		}


	};
	return (
		<>
			<div className="row-history-content">
				<div className="pr-0 col-history-list">
					<Card className="history-card">
						<CardBody
							onScroll={handleScroll}
							style={{ height: heightContent, overflow: "auto" }}>
							<HistoryList
								listOrders={listOrders}
								selectedId={selectedId}
								clickElement={(order) =>  clickOrder(order)}
							/>
						</CardBody>
					</Card>
				</div>
				<div className="pl-0 col-history-item">
					<Card>
						<CardBody style={{ height: heightContent, overflow: "auto" }}>
							<Row className="h-100">
								<Col xs="12">
									{selectedId ? (
										<OrderDetailLoad
											idOrder={selectedId}
											reloadOrder={reloadOrder}
											setOrderInEdit={setOrderInEdit}
										/>
									) : (
										<div
											className="p-1 text-center w-100 h-100"
											style={{
												background: `url(${imgFolder}) center center no-repeat`,
												backgroundSize: `20%`,
											}}>
											Nessun dato trovato
											{/* <div>
												<img
													src={imgFolder}
													class="img-fluid pt-5 mt-5"
													width={"200px"}
												/>
											</div> */}
										</div>
									)}
								</Col>
							</Row>
						</CardBody>
					</Card>
				</div>
			</div>
		</>
	);
};
export default HistoryDetail;
