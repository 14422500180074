import React from "react";
import { useSelector } from "react-redux";
import { selectSelectedCustomer } from "../../../../../redux/customer/customerSlice";
import { selectUser } from "../../../../modules/Auth/_redux/authRedux";
import { dateformat_ggMMMyyyy } from "../../../../utils/dateUtils";
import { findStates } from "../../../../utils/stateData";
import Badge from "../../../ui/badge/Badge";
import Caption from "../../../ui/headings/caption/Caption";

const ItemHistory = ({ order, active, clickElement }) => {
  const state = findStates(order.state);

  const selectedCustomer = useSelector(selectSelectedCustomer);
  const user = useSelector(selectUser);

  return (
    <div
      className={`shared-itemHistory ${active ? "active" : ""}`}
      onClick={clickElement}
    >
      <div className="time-row">
        <span>
          <small>N.RDO:</small> <br /> SG{order.rdOLabel}
        </span>
        <span>
          <small>N. Ordine:</small> <br />
          {order?.channel === "sg" && (
            <Badge bgColor="#DEE67F" color="#616800" className={"sm"}>
              PORTALE
            </Badge>
          )}
          {order.orderNumber ? order.orderNumber : ""}
        </span>
        <span>
          <small>del</small>
          <br />{" "}
          {dateformat_ggMMMyyyy(order.createdDate || order.dataCreazioneOrdine)}
        </span>
      </div>
      <div className="user-row">
        <span>
          Richiedente: {order?.name} - {order?.user_create}
        </span>
      </div>
      <Caption
        className="ellipsis"
        size="16px"
        title={!!order?.products?.length && order?.products[0]?.title}
      >
        {!!order?.products?.length && order?.products[0]?.title}
      </Caption>
      <div className="practice-row">
        <span className="d-flex">
          <span>Consegna: </span>
          <Caption className="primary bold ml-1" size="16px">
            {order.patient || "Farmacia"}
          </Caption>
        </span>
        <Badge bgColor={state?.tagBgColor}>{state?.label}</Badge>
      </div>
    </div>
  );
};

export default ItemHistory;
