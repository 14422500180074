export const finalizeOrderStep = {
    detail: 'detail',
    finalize: 'finalize',
    summary: 'summary',
}

export const nameSteps = {
    patient: 'patient',
    products: 'products',
    ...finalizeOrderStep,
    cart: 'cart'
}

const commonSteps = [
    { label: "Finalizza", name: nameSteps.finalize },
    { label: "Riepilogo", name: nameSteps.summary }
]

export const listStepPatient = [
    { label: "Scelta paziente", name: nameSteps.patient},
    { label: "Trattamenti",  name: nameSteps.products},
    { label: "Dettaglio", name: nameSteps.detail },
    ...commonSteps
]

export const listStepPharmacyFromCart = [

        { label: "Carrello", name: nameSteps.cart },
        ...commonSteps

]
export const listStepPharmacyFromProduct = [

        { label: "Prodotti",  name: nameSteps.products},
        { label: "Dettaglio", name: nameSteps.detail },
        ...commonSteps

]

export const listStepPatientEdit = [
    { label: "Modifica Trattamento", name: nameSteps.detail },
    ...commonSteps

]


export const getClassicListSteps = (isPharmacy, fromCart) => {
    return  isPharmacy ? (fromCart ? listStepPharmacyFromCart : listStepPharmacyFromProduct): listStepPatient
}

export const getListSteps = (isPharmacy, fromCart, selectedStep, isEdit) => {
    return  isEdit && Object.values(finalizeOrderStep).includes(selectedStep) ? listStepPatientEdit :getClassicListSteps(isPharmacy, fromCart)
}

export const getListWithSelectedStep = (isPharmacy, fromCart, selectedStep, isEdit) => {
    const listStep = getListSteps(isPharmacy, fromCart, selectedStep, isEdit);
    const index = listStep.findIndex(ele => ele.name === selectedStep)
    return listStep.map((ele, i) => ({
        label: ele.label, isActive: i<=index
    }))
}
