import { createSlice} from "@reduxjs/toolkit";

const initialCustomerState = {
  containers: []
};


export const containerSlice = createSlice({
  name: "container",
  initialState: initialCustomerState,
  reducers: {
    setContainers: (state, action) => {
      state.containers = action.payload;
    },
    resetCustomers: (state) => {
      state.containers = []
    }
  }
});

export const {setContainers, resetCustomers} = containerSlice.actions;

export const selectContainers = (state) => state.container.containers;
