import http from "../../app/utils/http";

export const CART_URL = "cart";


export const getCartCall = () => http.get(`${CART_URL}`);
export const saveCartCall = (data) => http.post(`${CART_URL}`, data);
export const addProductToCartCall = (data) => http.post(`${CART_URL}`, data);
export const editProductCartCall = (id, data) => http.patch(`${CART_URL}/${id}`, data);
export const deleteProductByCartCall = (id, data) => http.delete(`${CART_URL}/${id}`);

