import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
	Card,
	CardBody,
	CardHeader
} from "../../../_metronic/_partials/controls";
import WizardOrder from "../../shared/app/wizard_order/WizardOrder";
import { nameSteps } from "../../shared/app/wizard_order/wizardOrderData";
import PatientRegistry from "../sample/anagraficaPaziente/PatientRegistry";

export function PatientPage({ history }) {
	return (
		<Container>
			<Row>
				<Col xs="12">
					<WizardOrder selectedStep={nameSteps.patient} />
					<Card>
						<CardHeader title="Paziente" />
						<CardBody>
							<PatientRegistry />
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}
