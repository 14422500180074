import * as requestFromServer from "./customerCrud";
import { setErrorTypeAction } from "../errors/errorsActions";
import { setCustomers, setSelectedCustomer } from "./customerSlice";
import { GetAllProducts } from "../products/productsActions";
import {GetCartAction} from "../cart/cartActions";
import authRoute from "../../app/routes/auth";
import routes from "../../app/routes/auth";

export const GetCustomerAction = () => (dispatch, getState) => {
	return requestFromServer
		.getCustomers()
		.then((response) => {
			dispatch(setCustomers(response));
			const user = getState().auth.user;
			if(user.role === 'bo'){
				routes.firstPage = '/richieste'
			}
			const idCustomer = localStorage.getItem('customerInit');
			const initCustomer = idCustomer && response.find(ele => ele.id === idCustomer) ? response.find(ele => ele.id === idCustomer) : response[0];
			dispatch(setSelectedCustomer(initCustomer))
			dispatch(InitCustomerAction());
		})
		.catch((error) => {
			dispatch(setErrorTypeAction("Errore nel caricamento dei clienti"));
		});
};
export const InitCustomerAction = () => (dispatch, getState) => {
			dispatch(GetCartAction());
			dispatch(GetAllProducts());
};
