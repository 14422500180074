import React from "react";

import PrimaryButton from "../../../../shared/ui/buttons/primary-button/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { setEditOrderData } from "../../../../../redux/order/orderSlice";
import { useRoute } from "../../../../utils/useRoute";
import * as routeName from "../../../../routes/routeNameAuthType";
import { Col, Row } from "react-bootstrap";
import { saveOrder } from "../../../../../redux/order/orderCrud";
import { GetCartAction } from "../../../../../redux/cart/cartActions";
import { clearOrder } from "../../../../utils/ordersUtils";
import {
	resetModal,
	setModal,
} from "../../../../shared/redux/modal/modalSlice";
import {
	setErrorTypeAction,
	setSuccessErrorAction,
} from "../../../../../redux/errors/errorsActions";
import { selectSelectedCustomer } from "../../../../../redux/customer/customerSlice";
import { setPatient } from "../../../../../redux/patient/patientSlice";
import { setEditOrderDataAction } from "../../../../../redux/order/orderActions";
import { ActionsName } from "./ActionsData";

export function ProductActionOrder({ actions, order }) {
	const dispatch = useDispatch();
	const { changeRoute } = useRoute();
	const customer = useSelector(selectSelectedCustomer);
	const createNewOrder = (type) => {
		type === "Suspension" ? confirmSuspensionModal(type) : editOrder(type);
	};
	const buttonList = [
		{
			label: `Annulla`,
			action: "closeModal",
			type: "secondary",
			dataType: {},
		},
		{
			label: `Conferma`,
			action: "startOrder",
			type: "primary",
			dataType: {},
		},
	];
	const confirmSuspensionModal = (type) => {
		dispatch(
			setModal(
				buttonList,
				{
					title: `Vuoi confermare la sospensione?`,
				},
				{
					startOrder: () => startOrder(type),
					closeModal,
				}
			)
		);
	};

	const closeModal = () => {
		dispatch(resetModal());
	};

	const startOrder = (type) => {
		dispatch(resetModal());
		saveOrder(clearOrder({ ...order, type, customerId: customer.id })).then(
			(res) => {
				dispatch(GetCartAction());
				dispatch(setSuccessErrorAction("Sospensione effettuata con successo"));
			},
			(err) => {
				dispatch(setErrorTypeAction("Errore nella sospensione"));
			}
		);
	};

	const editOrder = (type) => {
		dispatch(setPatient(order.orderDetail.patient));
		dispatch(setEditOrderDataAction({ ...order, type }));
		changeRoute(routeName.selectEditProduct);
	};

	return (
		<Row className="justify-content-end">
			{actions.map((ele, key) => (
				<Col xs="auto" key={key}>
					<PrimaryButton size="sm" onClick={() => createNewOrder(ele)}>
						{ActionsName[ele]}
					</PrimaryButton>
				</Col>
			))}
		</Row>
	);
}
