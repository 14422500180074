import {createSelector, createSlice} from "@reduxjs/toolkit";
import {cart} from "../../app/routes/routeNameAuthType";
import {selectCart} from "../cart/cartSlice";
import {selectIsPharmacy} from "../customer/customerSlice";
import {selectPatient} from "../patient/patientSlice";

const initialCustomersState = {
  entities: [],
  types: [],
  categories: [],
  templates: [],
  selectedProduct: undefined
};


export const productsSlice = createSlice({
  name: "products",
  initialState: initialCustomersState,
  reducers: {
    // listProducts
    setProducts: (state, action) => {
      state.entities = action.payload;
    },
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },
    setSelectedProductId: (state, action) => {
      state.selectedProduct = state.entities.find(ele => ele.id === action.payload);
    }
  }
});

export const {setProducts, setSelectedProduct, setSelectedProductId} = productsSlice.actions;

export const selectListProducts = (state) => state.products.entities;
export const selectSelectedProduct = (state) => state.products.selectedProduct;
export const selectDisabledProduct = createSelector(selectListProducts, selectIsPharmacy, selectCart, (products, isPharmacy, cart) => {
  const cartTemplateRdo = cart && cart[0]? cart[0].templateRdo : undefined;
  return cartTemplateRdo && isPharmacy ? products.filter(pr => pr.templateRdo !== cartTemplateRdo).map(pr => pr.id) : []
} );
export const selectSelectedProductIsInCart = createSelector(selectSelectedProduct, selectCart, (product, cart)=> {
  return product && !!cart.find(ele => ele.id === product.id)
});

export const selectSelectedProductCart = createSelector(selectSelectedProduct, selectCart, selectIsPharmacy, selectPatient, (product, cart, isPharmacy, patient)=> {
  return product && cart.find(ele => ele.id === product.id && (isPharmacy || patient?.id === ele?.detail?.patient?.id))
});

export const selectSelectedProductWithInfo = createSelector(selectSelectedProduct, selectSelectedProductCart, (product, productCart)=> {
  return product && {...(productCart || {quantity: 1}), ...product}
});

