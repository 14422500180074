import React from "react";
import ToastMsg from "../../../toast/ToastMsg";

const styleSuccess = {
	style: { color: "#009B3C", bgColor: "#fff" },
	icon: { color: "#009B3C", ico: "success" },
};

const ToastSuccess = ({
	className,
	title,
	body,
	showToast = true,
	closeAction,
	...rest
}) => {
	return (
		<ToastMsg
			showToast={showToast}
			className="shared-toastSuccess"
			toastStyle={styleSuccess}
			title={title}
			body={body}
			closeAction={() => closeAction && closeAction()}></ToastMsg>
	);
};

export default ToastSuccess;
