import React from "react";
import { Col, Row } from "react-bootstrap";
import BoxLabel from "../../../../shared/ui/forms/box/BoxLabel";
import Caption from "../../../../shared/ui/headings/caption/Caption";
import Icon from "../../../../shared/ui/icon/Icon";
import Hr from "../../../../shared/ui/lineBrake/Hr";

const BoxSearchPatients = ({ patients, setPatient }) => {
	return (
		<>
			<Row className="mb-5">
				<Col xs="12" lg="9">
					<BoxLabel>
						<Caption>Pazienti</Caption>
						<Hr />
						<div className="content-box-label">
							{patients.map((pat, key) => (
								<div key={key}>
									<div
										className="d-flex pointer"
										onClick={() => setPatient(pat)}>
										<div className="w-100">
											<span className="mr-5">{pat.surname.toUpperCase()}</span>
											<span className="mr-5">{pat.name}</span>
											<span className="mr-5">{pat.cf}</span>
										</div>
										<span className="">
											<Icon icon="add_task" />
										</span>
									</div>
									{key < patients.length - 1 && <Hr />}
								</div>
							))}
						</div>
					</BoxLabel>
				</Col>
			</Row>
		</>
	);
};

export default BoxSearchPatients;
