import React from "react";
import { useSelector } from "react-redux";
import { selectIsPharmacy } from "../../../../../../redux/customer/customerSlice";
import { FormElements } from "../../../../../shared/ui/forms/FormElements";
import HeadingH4 from "../../../../../shared/ui/headings/headingH4/HeadingH4";
import {
	templateFormVentilotherapyOtherInfo,
	templateFormVentilotherapyPrescription,
} from "./templateForm";

export function TemplateVentilotherapy({ formik }) {
	const isPharmacy = useSelector(selectIsPharmacy);

	return (
		<>
			{!isPharmacy && <HeadingH4>Prescrizione</HeadingH4>}
			<FormElements
				structure={templateFormVentilotherapyPrescription}
				formik={formik}></FormElements>
			<HeadingH4 className="mt-3">Altre info</HeadingH4>
			<FormElements
				structure={templateFormVentilotherapyOtherInfo}
				formik={formik}></FormElements>
		</>
	);
}
