
import {addError, removeError, setCatalogs} from "./errorsSlice";




export const setSuccessErrorAction = (title, body) => dispatch => {
      dispatch(setErrorAction('success', title, body, true))
};

export const setErrorTypeAction = (title, body) => dispatch => {
      dispatch(setErrorAction('error', title, body))
};


export const setErrorAction = (type, title, body, autoRemove) => dispatch => {
    const id = Date.now()*(Math.round(Math.random() * 100));
      dispatch(addError(
          {
                id,
                type,
                title,
                body,
          }
      ))
    if(autoRemove){
        setTimeout(() => {
            dispatch(removeError(id))
        }, 5000)
    }

};
