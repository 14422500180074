import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import { useDispatch, useSelector } from "react-redux";
import { selectCart } from "../../../redux/cart/cartSlice";
import {
  deleteProductByCartAction,
  UpdateQuantityProductToCartAction,
} from "../../../redux/cart/cartActions";
import { ProductCart } from "./ProductCart";
import { Col, Container, Row } from "react-bootstrap";
import PrimaryButton from "../../shared/ui/buttons/primary-button/PrimaryButton";
import { setFromCart } from "../../../redux/order/orderSlice";
import { useRoute } from "../../utils/useRoute";
import * as routeName from "../../routes/routeNameAuthType";
import { setSelectedProductId } from "../../../redux/products/productsSlice";
import { selectIsPharmacy } from "../../../redux/customer/customerSlice";
import { setPatient } from "../../../redux/patient/patientSlice";
import WizardGroup from "../../shared/ui/wizard/group/WizardGroup";
import { setOrderDataAction } from "../../../redux/order/orderActions";
import { nameSteps } from "../../shared/app/wizard_order/wizardOrderData";
import WizardOrder from "../../shared/app/wizard_order/WizardOrder";
import { RowTotal } from "../order/component/rowTotal/RowTotal";
import Icon from "../../shared/ui/icon/Icon";

export function CartPage({ history }) {
  const dispatch = useDispatch();
  const cart = useSelector(selectCart);
  const isPharmacy = useSelector(selectIsPharmacy);
  useEffect(() => {
    dispatch(setFromCart(true));
  }, []);
  const changeQuantityProduct = (quantity, id) => {
    dispatch(UpdateQuantityProductToCartAction({ quantity }, id));
  };
  const deleteProductHandle = (id) => {
    dispatch(deleteProductByCartAction(id));
  };
  const { changeRoute } = useRoute();
  const createOrder = () => {
    dispatch(setOrderDataAction(cart));
    changeRoute(routeName.orderPage);
  };
  console.log("cart", cart);
  return (
    <Container>
      <Row>
        <Col xs="12">
          {isPharmacy && <WizardOrder selectedStep={nameSteps.cart} />}
          <Card>
            {/* <CardHeader title={isPharmacy ? "Carrello" : "Bozza richiesta"} /> */}
            <CardBody>
              <Row>
                {cart.length > 0 &&
                  cart.map((product, i) => (
                    <Col xs="12" key={i} className="mb-5">
                      <ProductCart
                        clickTitle={() => {
                          product?.detail?.patient &&
                            dispatch(setPatient(product.detail.patient));
                          dispatch(setSelectedProductId(product.id));
                          changeRoute(routeName.selectProduct);
                        }}
                        changeValue={(quantity) =>
                          changeQuantityProduct(quantity, product.id)
                        }
                        deleteProductCart={deleteProductHandle}
                        product={product}
                        key={product.id}
                      />
                    </Col>
                  ))}
                {cart.length <= 0 && (
                  <div className={"text-center w-100"}>
                    <h5
                      className={
                        "d-flex flex-column align-items-center justify-content-center"
                      }
                    >
                      <Icon
                        icon={"cart"}
                        size={"32px"}
                        className={"mb-3"}
                      ></Icon>
                      Il carrello è vuoto.
                    </h5>
                  </div>
                )}
              </Row>
              <RowTotal products={cart} />
              {isPharmacy && (
                <Row className="justify-content-end mt-3">
                  <Col xs="auto">
                    <PrimaryButton
                      data-test="cart-finalize"
                      onClick={createOrder}
                      disabled={cart.length <= 0}
                    >
                      Finalizza
                    </PrimaryButton>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
