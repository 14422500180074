import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import * as routeName from "../../../../routes/routeNameAuthType";
import { useRoute } from "../../../../utils/useRoute";
import Icon from "../../../ui/icon/Icon";
import IconBtn from "../../buttons/icon-button/IconButton";
import { InputCustom } from "../input/InputCustom";
import ItemDropdown from "./ItemDropdown";
import Loader from "../../loader/loader/Loader";

let searchTimeout;
export function SearchInput({
	searchAction,
	clickUser,
	listUser = [],
	resetUsers,
	loading = false,
}) {
	const { changeRoute } = useRoute();

	const formik = useFormik({
		initialValues: {
			name: "",
		},
		onSubmit: (values) => {
			searchAction && searchAction(values);
		},
	});
	const data = {
		name: "name",
	};

	const changeValueHandler = (value) => {
		formik.setFieldValue(data.name, value);
		searchTimeout && clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			if (value && value.length > 2)
				searchAction && searchAction({ name: value });
			else resetUsers && resetUsers();
		}, 300);
	};
	const goNewPatient = () => {
		changeRoute(routeName.patientPage);
	};
	return (
		<>
			<Form onSubmit={formik.handleSubmit} className="form-search">
				<InputCustom
					rightTemplate={<Icon icon="search" colorStyle="#00A0E1" />}
					ele={data}
					val={formik.values.name}
					changeValue={changeValueHandler}
				/>
				<div className="dropdown-search-input">
					<div className="drop-content">
						{loading && (
							<div className="loader-drop">
								<Loader size="x40" />
							</div>
						)}
						{listUser && listUser.length > 0 ? (
							listUser.map((usr) => (
								<ItemDropdown
									user={usr}
									clickHandler={(usr) => clickUser(usr)}
								/>
							))
						) : (
							<div className="text-center py-3">
								{formik.values.name?.length < 3 ? (
									<div>
										Inserisci almeno 3 caratteri per cercare il paziente
									</div>
								) : (
									<div>
										{loading
											? "Ricerca in corso..."
											: "Nessun paziente trovato"}
									</div>
								)}
							</div>
						)}
					</div>

					<div className="drop-footer">
						<IconBtn
							className="mt-3"
							isFontAwesome
							icon={faUserPlus}
							sizeBtn="2px"
							onClick={goNewPatient}>
							Aggiungi nuovo paziente
						</IconBtn>
					</div>
				</div>
			</Form>
		</>
	);
}
