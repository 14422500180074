import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectIsPharmacy } from "../../../../../redux/customer/customerSlice";
import PrimaryButton from "../../../../shared/ui/buttons/primary-button/PrimaryButton";
import SecondaryButton from "../../../../shared/ui/buttons/secondary-button/SecondaryButton";
import { OrderDetailComponent } from "./OrderDetailComponent";

export function SummaryOrder({
  orderDetail,
  order,
  resetSummary,
  createOrder,
}) {
  const isPharmacy = useSelector(selectIsPharmacy);
  return (
    <>
      <OrderDetailComponent order={order} orderDetail={orderDetail} />
      <Row className="justify-content-end mt-3">
        <Col xs="auto">
          <SecondaryButton className="mr-3" onClick={resetSummary}>
            Indietro
          </SecondaryButton>
        </Col>
        <Col xs="auto">
          <PrimaryButton data-test="order-send" onClick={createOrder}>{`Invia ${
            isPharmacy ? "Ordine" : "Richiesta"
          }`}</PrimaryButton>
        </Col>
      </Row>
    </>
  );
}
