import * as requestFromServer from "./productsCrud";
import {
	setProducts, setSelectedProduct
} from "./productsSlice";
import {
	setErrorTypeAction
} from "../errors/errorsActions";



export const GetAllProducts = () => (dispatch, getState) => {
	const customer = getState().customer.selectedCustomer;
	return requestFromServer
		.getProducts(customer)
		.then((response) => {
			dispatch(setProducts(response));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction("Errore nel caricamento dei prodotti"));
		});
};

export const SelectProductAction = (product) => (dispatch) => {
		dispatch(setSelectedProduct(product));
};

