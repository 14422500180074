import React, { useEffect } from "react";
import { FormElements } from "../../../../shared/ui/forms/FormElements";
import { formStructureIndirizzoConsegnaRow } from "../../../sample/templateGenFar/templateGenFarData";
import { useFormik } from "formik";
import PrimaryButton from "../../../../shared/ui/buttons/primary-button/PrimaryButton";
import SecondaryButton from "../../../../shared/ui/buttons/secondary-button/SecondaryButton";
import HeadingH4 from "../../../../shared/ui/headings/headingH4/HeadingH4";
import { containerCommunications } from "../../../../../redux/container/containerCrud";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedCustomer } from "../../../../../redux/customer/customerSlice";
import { updatePatient } from "../../../../../redux/order/orderCrud";
import { Row } from "react-bootstrap";
import { setErrorTypeAction } from "../../../../../redux/errors/errorsActions";

export function Collect({ container, resetShow }) {
	const customer = useSelector(selectSelectedCustomer);
	const dispatch = useDispatch();
	const formik = useFormik({
		initialValues: {},
		onSubmit: (values) => {},
	});

	useEffect(() => {
		container?.deliveryPlace &&
			formik.setFieldValue("patient", container.deliveryPlace);
	}, [container]);

	const collectAction = () => {
		updatePatient(formik.values.patient).then((res) => {
			containerCommunications({
				clientCode: customer.id,
				patientCode: formik.values.patient.id,
				note: formik.values.note,
				serial: container.serial,
			})
				.then((res) => resetShow())
				.catch((error) => {
					dispatch(setErrorTypeAction("Errore nell'invio della richiesta"));
				});
		}, err => {
			const message = err?.response?.data?.Message;
			if(message === 'ERR_CF_DUPLICATE') {
				dispatch(setErrorTypeAction("Codice Fiscale già registrato"))
			}
			else if(message === 'ERR_CF_INCOHERENT'){
				dispatch(setErrorTypeAction("Codice Fiscale errato"))
			}
			else if(message === 'ERR_EMAIL_DUPLICATE'){
				dispatch(setErrorTypeAction("L'email è già registrata"))
			}
			else if(message === 'ERR_EMAIL_INCOHERENT'){
				dispatch(setErrorTypeAction("L'email non è corretta"))
			}
			else {
				dispatch(setErrorTypeAction("Errore nell'aggiornamento del paziente"))
			}
		});
	};
	const formStructureNominativeRow = [
		{
			name: "patient.surname",
			label: "Cognome",
			type: "input",
			disabled: true,
			col: { xs: "12", sm: "6", lg: "3" },
		},
		{
			name: "patient.name",
			label: "Nome",
			type: "input",
			disabled: true,
			col: { xs: "12", sm: "6", lg: "3" },
		},
		{
			name: "patient.phone",
			label: "Telefono paziente",
			type: "input",
			col: { xs: "12", sm: "6", lg: "3" },
		},
		{
			name: "note",
			label: "Note",
			type: "textarea",
			col: { xs: "9" },
		},
	];
	return (
		<div>
			<FormElements
				structure={formStructureNominativeRow}
				formik={formik}></FormElements>
			<HeadingH4>Indirizzo di ritiro</HeadingH4>
			<FormElements
				structure={formStructureIndirizzoConsegnaRow}
				formik={formik}></FormElements>
			<Row className="justify-content-end">
				<SecondaryButton className="mr-3" onClick={resetShow}>
					Annulla
				</SecondaryButton>
				<PrimaryButton onClick={collectAction}>Invia Richiesta</PrimaryButton>
			</Row>
		</div>
	);
}
