import React from "react";
import { Dropdown } from "react-bootstrap";
import PrimaryButton from "../../../../shared/ui/buttons/primary-button/PrimaryButton";
import {findAction} from "../../../../utils/actionData";

export function DropDelete({ ele, clickClient, clickBO }) {
	return (
		<Dropdown alignRight drop="down" onToggle={() => {}} id="drop-actio">
			<Dropdown.Toggle className="btn-sm shared-button sh-primary">{findAction(ele.id).label}</Dropdown.Toggle>
			<Dropdown.Menu className="dropdown-menu m-0 mt-1 p-5 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-md">
				<div className="">
					<div className="mb-3">
						<PrimaryButton
							onClick={clickClient}
							size="sm"
							className="w-100 error">
							Su richiesta Cliente
						</PrimaryButton>
					</div>
					<div id="kt_quick_search_dropdown">
						<PrimaryButton onClick={clickBO} size="sm" className="w-100 error">
							Su iniziativa Back Office
						</PrimaryButton>
					</div>
				</div>
			</Dropdown.Menu>
		</Dropdown>
	);
}
