import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setErrorTypeAction } from "../../../redux/errors/errorsActions";
import { GetPatientAction } from "../../../redux/patient/patientActions";
import { getPatients } from "../../../redux/patient/patientCrud";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../_metronic/_partials/controls";
import imgSearchPatient from "../../assets/img/searchPatient.jpg";
import * as routeName from "../../routes/routeNameAuthType";
import TitleCard from "../../shared/app/titleCard/TitleCard";
import WizardOrder from "../../shared/app/wizard_order/WizardOrder";
import { nameSteps } from "../../shared/app/wizard_order/wizardOrderData";
import { SearchInput } from "../../shared/ui/forms/searchInput/SearchInput";
import { setStopLoader } from "../../shared/ui/loader/redux/loadSlice";
import { useRoute } from "../../utils/useRoute";
import { selectSelectedCustomer } from "../../../redux/customer/customerSlice";
export function PatientsPage({ history }) {
	const [loading, setLoading] = useState(false);

	const [users, setUsers] = useState([]);
	const selectedCustomer = useSelector(selectSelectedCustomer);
	const dispatch = useDispatch();
	const searchAction = ({ name: searchAll }) => {
		setLoading(true);
		getPatients({ searchAll, customerCode: selectedCustomer.id })
			.then((res) => {
				setUsers(res);
				setLoading(false);
			})
			.catch((error) => {
				dispatch(setErrorTypeAction("Errore nel caricamento pazienti"));
				setLoading(false);
			});
	};
	const { changeRoute } = useRoute();
	useEffect(() => {
		dispatch(setStopLoader(true));
		return () => {
			dispatch(setStopLoader(false));
		};
	});

	const clickUser = (usr) => {
		const callback = () => changeRoute(routeName.selectedPatientPage);
		dispatch(GetPatientAction(usr.id, callback));
	};
	const heightContent = `calc(100vh - 320px)`;

	return (
		<Container>
			<Row>
				<Col xs="12">
					<WizardOrder selectedStep={nameSteps.patient} />
					<Card>
						<CardHeader>
							<TitleCard
								title="Gestione Pazienti"
								subtitle="Inserimento e/o Ricerca per Cognome, Nome, Codice Fiscale del paziente."
							/>
						</CardHeader>
						<CardBody
							style={{
								height: heightContent,
								backgroundImage: `url(${imgSearchPatient})`,
							}}
							className="bg-img-patient-page">
							<Row className="my-5 justify-content-center">
								<Col xs="5">
									<SearchInput
										searchAction={searchAction}
										clickUser={clickUser}
										listUser={users}
										resetUsers={() => setUsers([])}
										loading={loading}
									/>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}
