/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { dateformat_ggMMMyyyy } from "../../../../utils/dateUtils";

export function DeliveryDateColumnFormatter(cellContent, row, rowIndex) {
	return (
		<>
			<span>{!!row.deliveryPlace && dateformat_ggMMMyyyy(row.deliveryDate)}</span>
		</>
	);
}
