import React from "react";
import { Toast } from "react-bootstrap";
import Icon from "../icon/Icon";

const ToastMsg = ({
	className,
	toastStyle,
	title,
	body,
	showToast = true,
	closeAction,
}) => {
	return (
		<div className={`shared-toast ${className}`}>
			<Toast
				show={showToast}
				onClose={closeAction}
				style={{
					backgroundColor: toastStyle.style.bgColor,
					color: toastStyle.style.color,
				}}>
				<Toast.Header
					style={{
						backgroundColor: toastStyle.style.bgColor,
						color: toastStyle.style.color,
					}}>
					<Icon
						className="mr-2"
						icon={toastStyle.icon.ico}
						color={toastStyle.icon.color}
						size="x26"></Icon> 
					<strong className="mr-auto">{title}</strong>
				</Toast.Header>
				{body && <Toast.Body>{body}</Toast.Body>}
			</Toast>
		</div>
	);
};

export default ToastMsg;
