import React, {useCallback} from "react";
import WizardGroup from "../../ui/wizard/group/WizardGroup";
import {useSelector} from "react-redux";
import {selectIsPharmacy} from "../../../../redux/customer/customerSlice";
import {selectCurrentOrderType, selectFromCart, selectIsEditOrder} from "../../../../redux/order/orderSlice";
import { getListWithSelectedStep} from "./wizardOrderData";


const WizardOrder = ({selectedStep}) => {
	const isPharmacy = useSelector(selectIsPharmacy);
	const fromCart = useSelector(selectFromCart);
	const isEdit = useSelector(selectIsEditOrder);
	const listStep = useCallback(() => {
		return getListWithSelectedStep(isPharmacy, fromCart, selectedStep, isEdit)
	}, [isPharmacy, fromCart, selectedStep, isEdit])
	return (
		<WizardGroup
			listStep={listStep() || []}
		/>
	);
};

export default WizardOrder;
