import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../_metronic/_partials/controls";
import { useDispatch, useSelector } from "react-redux";
import {
	resetOrderData, selectCurrentOrder,
	selectFromCart, selectIsEditOrder,
	selectOrderData,
} from "../../../redux/order/orderSlice";
import { saveOrder } from "../../../redux/order/orderCrud";
import {
	selectIsPharmacy,
	selectSelectedCustomer,
} from "../../../redux/customer/customerSlice";
import { SummaryOrder } from "./component/summary/SummaryOrder";
import { useRoute } from "../../utils/useRoute";
import * as routeName from "../../routes/routeNameAuthType";
import { DetailOrderTemplate } from "./DetailOrderTemplate";
import { GetCartAction } from "../../../redux/cart/cartActions";
import { Col, Container, Row } from "react-bootstrap";
import { resetModal, setModal } from "../../shared/redux/modal/modalSlice";
import UserDetail from "../../shared/ui/userDetail/UserDetail";
import { selectPatient } from "../../../redux/patient/patientSlice";
import { SummaryLightOrder } from "./SummaryLightOrder";
import WizardOrder from "../../shared/app/wizard_order/WizardOrder";
import { nameSteps } from "../../shared/app/wizard_order/wizardOrderData";
import { copyObject } from "../../utils/objUtils";
import TitleCard from "../../shared/app/titleCard/TitleCard";
import { UseTitleApp } from "../../core/useTitleApp";
import { pages } from "../../core/titleApp";
import { setErrorTypeAction } from "../../../redux/errors/errorsActions";

export function OrderPage({ history }) {
	const dispatch = useDispatch();
	const order = useSelector(selectCurrentOrder);
	const isEditOrder = useSelector(selectIsEditOrder)
	const orderFinal = useSelector(selectOrderData);
	const customer = useSelector(selectSelectedCustomer);
	const isPharmacy = useSelector(selectIsPharmacy);
	const patient = useSelector(selectPatient);
	const [orderDetail, setOrderDetail] = useState(undefined);
	const [showSummary, setShowSummary] = useState(false);
	const buttonList = [
		{
			label: `Vai a ${isPharmacy ? "Prodotti" : "Pazienti"}`,
			action: "confirmSendOrder",
			type: "primary",
			dataType: {},
		},
	];
	const { changeRoute } = useRoute();
	useEffect(() => {
		(!order || !Object.keys(order)?.length) &&
			changeRoute(isPharmacy ? routeName.cart : routeName.patientsPage);
		order?.orderDetail && setOrderDetail(order);
		return () => {
			dispatch(resetOrderData());
		};
	}, []);
	const createSummary = (orderDetail) => {
		setOrderDetail({
			orderDetail: orderDetail,
			productCart: order.products,
			orderRequest: order.orderRequest,
			type: order.type,
			customerId: customer.id,
			rdoId: order.rdoId,
		});
		setShowSummary(true);
	};
	const confirmSendOrder = () => {
		dispatch(resetModal());
		isPharmacy
			? changeRoute(routeName.products)
			: changeRoute(routeName.patientsPage);
	};

	const sendOrder = () => {
		dispatch(
			setModal(
				buttonList,
				{
					title: `${
						isPharmacy ? "Ordine inviato" : "Richiesta inviata"
					} con successo`,
				},
				{
					confirmSendOrder,
				}
			)
		);
	};
	const createOrder = () => {
		const data = copyObject(orderDetail);
		if (patient) {
			data.orderDetail.patient = patient;
		}
		saveOrder(data)
			.then((res) => {
				dispatch(GetCartAction());
				sendOrder();
			})
			.catch((error) => {
				dispatch(setErrorTypeAction("Errore nel salvataggio"));
			});
	};
	const goBack = () => {
		changeRoute(isEditOrder? routeName.selectEditProduct : (isPharmacy ? routeName.cart : routeName.selectProduct));
	};
	const { label } = UseTitleApp(
		!orderDetail || !showSummary ? pages.summary : pages.summaryFinal,
		isPharmacy
	);

	return (
		<Container>
			<Row>
				<Col xs="12">
					<WizardOrder
						selectedStep={showSummary ? nameSteps.summary : nameSteps.finalize}
					/>
					<Card>
						<CardHeader>
							{(patient || orderDetail?.orderDetail?.patient) && (
								<UserDetail
									customer={patient || orderDetail?.orderDetail?.patient}
								/>
							)}
							<TitleCard title={label.title} subtitle={label.subtitle} />
						</CardHeader>
						<CardBody>
							{(!orderDetail || !showSummary) && (
								<Row>
									<Col xs="12" md="12" lg="2" className="mb-5">
										<SummaryLightOrder
											isPharmacy={isPharmacy}
											products={order?.products}
										/>
									</Col>
									<Col xs="12" md="12" lg="10">
										<DetailOrderTemplate
											goBack={goBack}
											customer={customer}
											createOrder={createSummary}
											order={orderDetail?.orderDetail}
											templateRDO={order?.orderRequest?.templateRDO}
										/>
									</Col>
								</Row>
							)}

							{orderDetail && showSummary && (
								<SummaryOrder
									orderDetail={orderDetail.orderDetail}
									order={order}
									createOrder={createOrder}
									resetSummary={() => setShowSummary(false)}
								/>
							)}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}
