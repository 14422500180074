import React from "react";
import { templateNameId, templateNameType } from "../../../../cart/templateData";
import { TemplateNoteRead } from "./templateRead/TemplateNoteRead";
import { TemplateOxigenRead } from "./templateRead/TemplateOxigenRead";
import { TemplateVentilotherapyRead } from "./templateRead/TemplateVentilotherapyRead";

export function TemplateSelectProductRead({template, data}) {
	const templateList = {
		[templateNameId[templateNameType.note]]: <TemplateNoteRead data={data}/>,
		[templateNameId[templateNameType.ossigeno]]: <TemplateOxigenRead data={data}/>,
		[templateNameId[templateNameType.ventiloterapia]]: <TemplateVentilotherapyRead data={data}/>,
	}
	return (
		<>
			{templateList[template]}
		</>
	);
}
