export const actions = [
	{
		id: 1,
		code: "Send",
		label: "Invia",
	},
	{
		id: 2,
		code: "Approve",
		label: "Approva",
	},
	{
		id: 3,
		code: "Deny",
		label: "Rifiuta",
	},
	{
		id: 4,
		code: "Delete",
		label: "Richiesta cancellazione",
		msg: {
			title: "Conferma richiesta cancellazione",
			body: "Sei sicuro di voler richiedere la cancellazione?",
		},
	},
	{
		id: 5,
		code: "Delete",
		label: "Cancella",
		msg: {
			title: "Conferma cancellazione",
			body: "Sei sicuro di voler confermare la cancellazione?",
		},
	},
	{
		id: 6,
		code: "Reject-Cancellation",
		label: "Rifiuta richiesta cancellazione",
	},
	{
		id: 7,
		code: "Close-with-Positive-Result",
		label: "Chiudi con esito positivo",
	},
	{
		id: 8,
		code: "work",
		label: "Lavora",
	},
	{
		id: 9,
		code: "work-end",
		label: "Lavorazione conclusa",
	},
];

export const findAction = (id: number | string) => {
	return actions.find((action) => action.id === +id);
};
