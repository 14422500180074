/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { dateformat_ggMMMyyyy } from "../../../../utils/dateUtils";

export function DeliveryColumnFormatter(cellContent, row, rowIndex) {
	return (
		<>
			<span>{row.deliveryPlace?.surname?.toUpperCase()} {row.deliveryPlace?.name} {row.deliveryPlace?.phone}</span>
		</>
	);
}
