import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRoute } from "../../../utils/useRoute";
import * as routeName from "../../../routes/routeNameAuthType";
import { selectIsPharmacy } from "../../../../redux/customer/customerSlice";
import {
	setProductEditOrderDataActionPrescriber
} from "../../../../redux/order/orderActions";
import { selectPatient } from "../../../../redux/patient/patientSlice";
import { resetOrderData,
	selectEditOrderData,
	selectEditProduct,
	setFromCart
} from "../../../../redux/order/orderSlice";
import {SelectProductComponent} from "./component/SelectProductComponent";

export function EditProductPage({ history }) {
	const dispatch = useDispatch();
	const isPharmacy = useSelector(selectIsPharmacy);
	const orderData = useSelector(selectEditOrderData);
	const product = useSelector(selectEditProduct);
	console.log(product);
	const patient = useSelector(selectPatient);
	const { changeRoute } = useRoute();
	const changeRouteProducts = () => {
		changeRoute(routeName.products);
	};
	useEffect(() => {
		dispatch(setFromCart(false));
		dispatch(resetOrderData())
	}, []);
	useEffect(() => {
		!orderData && changeRouteProducts();
	}, [orderData]);


	const createOrder = (detail) => {
			const changeR = () => changeRoute(routeName.orderPage);
			 dispatch(
				 	setProductEditOrderDataActionPrescriber(
							{
								...product,
								detail: { ...detail, patient },
							},
						orderData,
							changeR
						)
				  );
	};

	return (
		<SelectProductComponent
			isPharmacy={isPharmacy}
			isInCart={true}
			createOrder={createOrder}
			changeRouteProducts={changeRouteProducts}
			product={product}
			patient={patient}
		/>
	);
}
