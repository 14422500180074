import { formStructureNoteRow } from "../../../../sample/templateGenFar/templateGenFarData";
import { pathologiesData } from "../pathologiesData";
export const templateFormNote = formStructureNoteRow;
export const templateFormOxygen = [
	{
		name: "diagnosis",
		label: "Diagnosi",
		type: "textarea",
		col: { md: "12" },
	},
	{
		name: "pathologies",
		label: "Patologie",
		type: "checkbox",
		options: pathologiesData,
		col: { md: "12" },
	},
	{
		name: "otherPathologies",
		label: "Altre patologie",
		type: "textarea",
		rows: 1,
		col: { md: "12" },
	},
	{
		name: "flowRest",
		label: "A riposo | flusso (l/min) ",
		type: "input",
		col: { md: "3", lg: "2" },
	},
	{
		name: "flowRestHourDie",
		label: "ore/die",
		type: "input",
		col: { md: "3", lg: "2" },
	},
	{
		name: "underStressFlow",
		label: "Sotto sforzo | flusso (l/min) ",
		type: "input",
		col: { md: "3", lg: "2" },
	},
	{
		name: "underStressFlowHourDie",
		label: "ore/die",
		type: "input",
		col: { md: "3", lg: "2" },
	},
	{
		name: "nightFlow",
		label: "Notturno | flusso (l/min) ",
		type: "input",
		col: { md: "3", lg: "2" },
	},
	{
		name: "nightFlowHourDie",
		label: "ore/die",
		type: "input",
		col: { md: "3", lg: "2" },
	},
	{
		name: "validUntil",
		label: "Valido fino al",
		type: "input",
		dataElement: {
			type: "date",
		},
		col: { md: "12", lg: "4" },
	},
	{
		name: "interface",
		label: "Interfaccia",
		type: "input",
		col: { md: "12", lg: "4" },
	},
	{
		name: "accessories",
		label: "Accessori vari",
		type: "select",
		options: [
			{ value: 1, label: "Cannula nasale" },
			{ value: 2, label: "Maschera oro-nasale" },
		],
		col: { md: "12", lg: "4" },
	},
	{
		name: "note",
		label: "Note",
		type: "textarea",
		col: { sm: "12" },
	},
];
export const templateFormVentilotherapyPrescription = [
	{
		name: "ipap",
		label: "IPAP/PS",
		type: "input",
		format: "integer",
		col: { sm: "3", md: "2", lg: "1" },
		className: "noTextWrap min-width-100px",
	},
	{
		name: "epap",
		label: "EPAP/PEEP",
		type: "input",
		format: "integer",
		col: { sm: "3", md: "2", lg: "1" },
		className: "noTextWrap min-width-100px",
	},
	{
		name: "freq",
		label: "FREQ",
		type: "input",
		col: { sm: "3", md: "2", lg: "1" },
		className: "noTextWrap min-width-100px",
	},
	{
		name: "tins",
		label: "TINS/I:E",
		type: "input",
		format: "integer",
		col: { sm: "3", md: "2", lg: "1" },
		className: "noTextWrap min-width-100px",
	},
	{
		name: "resignation",
		label: "Regolazioni alle dimissioni",
		type: "input",
		col: { sm: "12", lg: { offset: 0, span: 6 } },
	},
	{
		name: "note",
		label: "Note",
		type: "textarea",
		rows: 2,
		col: { sm: "12", md: "6" },
	},
	{
		name: "noteSafety",
		label: "Note (eventuale presenza di volume garantito di sicurezza)",
		type: "textarea",
		rows: 2,
		col: { sm: "12", md: "6" },
	},
];

export const templateFormVentilotherapyOtherInfo = [
	{
		name: "interface",
		label: "Interfaccia",
		type: "input",
		col: { sm: "6", md: "3" },
	},
	{
		name: "modelCannula",
		label: "Modello cannula tracheostomica",
		type: "input",
		col: { sm: "6", md: "3" },
	},
	{
		name: "modelVentilator",
		label: "Modello ventilatore utilizzato",
		type: "input",
		col: { sm: "6", md: "3" },
	},
	{
		name: "company",
		label: "Azienda produttrice",
		type: "input",
		col: { sm: "6", md: "3" },
	},
];
