import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetCustomerAction } from "../redux/customer/customerActions";
import {
  selectSelectedCustomer
} from "../redux/customer/customerSlice";
import {selectCustomerType, selectIsViewer} from "./modules/Auth/_redux/authRedux";
import authRoute from "./routes/auth";
import DynamicRoute from "./routes/DynamicRoute";
import {usePermissions} from "./shared/utils/usePemissions";

export default function BasePage() {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(GetCustomerAction());
	}, []);
	const {issetPermission} = usePermissions();
	const customer = useSelector(selectSelectedCustomer);
	const customerType = useSelector(selectCustomerType);
	return (
		<>
			{customer && (
				<DynamicRoute
					routes={{
						...authRoute,
						list: authRoute.list.filter(
							(r) => (!r.roles || r.roles.includes(customerType)) && (r.permissions && r.permissions.length ? issetPermission(r.permissions) : true)
						),
					}}
				/>
			)}
		</>
	);
}
