export const templateNameType = {
    note: 'note',
    ossigeno: 'ossigeno',
    ventiloterapia: 'ventiloterapia'
}

export const templateNameId = {
    [templateNameType.note]: 'a175b9f6-dfce-45d5-8f84-c823331e18fc',
    [templateNameType.ossigeno]: 'a175b9f6-dfce-45d5-8f84-c823331e18fb',
    [templateNameType.ventiloterapia]: 'c175b9f6-dfce-45d5-8f84-c823331e18fa'
}
export const templateData = [
    {
        id: templateNameId[templateNameType.ventiloterapia],
        descrizione: 'Ventiloterapia Generico',
        name: 'Template003',
        template: templateNameType.ventiloterapia
    },
    {
        id: templateNameId[templateNameType.ossigeno],
        descrizione: 'Ossigeno terapia generico',
        name: 'Template001',
        template: templateNameType.ossigeno
    },
    {
        id: templateNameId[templateNameType.note],
        descrizione: 'note generico',
        name: 'Template002',
        template: templateNameType.note
    }
]
